import { Component, OnInit, OnDestroy, ChangeDetectorRef,  Input, Inject, NgZone } from '@angular/core';
import {Store, select} from '@ngrx/store';
import { AuthService} from '../../../../store/services/auth.service';
import { Subscription } from 'rxjs';
import { map, take} from 'rxjs/operators';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { AvailabilityService } from 'src/app/store/services/availability.service';
import { ScheduleService } from 'src/app/store/services/schedule.service';
import { TeachersService } from 'src/app/store/services/teacher.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { SearchService } from 'src/app/store/services/search.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { logout } from 'src/app/store/actions/auth.actions';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { HttpClient } from '@angular/common/http';
import { ShortSchoolName} from 'src/app/common'
import { openJoinDialog } from '../../join/join-dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

declare var $: any;
export interface DialogData {
  dayOfWeek: string;
  location: string;
  startTime: string;
  endTime: string;
}
export interface FeedBackDialogData {
  firstValue: string;
  secondValue: string;
  thirdValue: string;  
  status: string;
  not_tutored: boolean
}
export interface TutorFeedBackDialogData {
  helps_status: any;
  secondValue: string;
  thirdValue: string;  
  status: string;
}

export interface SessionDialogData {
  id: string,
  tutor: string,
  tutors: [];
  classname: string,
  date: string,
  time: string,
  location: string,
  teacher: string,
  teachers: [],
  help: string,
  helps: [],
  comment: string,
  videoCallLink: string,
  existCallLink: boolean,
  filename: string,
  userType: string,
  status: string,
  fileList: any[],
  notes: string,
  student: string,
  tutorFileList: []
  tutorFileNameList: []
  noShowStatus: boolean
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
  authSubscription: Subscription;
  userData = null;
  appointmentsList = [];
  upcommingSessions = [];
  pastSessions = [];
  username = '';
  schoolID = '';
  schoolName = '';
  programName = '';
  dashLogo = "";
  supportEmail = "";
  myCourseList = null;  
  appointmentId = null;
  tutorId = null;
  isApproved = false;
  hasPermission = true;
  allTeachers = [];
  loginError = null;
  tutoringClasses = [];
  tutorClassList = [];
  hours_classes = [];
  isRefreshToken = false;
  isMaintenance = false;  
  availabilities_count = 1
  isPopUpSession = false;

  route: ActivatedRouteSnapshot;
  isActive = false;
  openJoinDialog = openJoinDialog
  selectorOpen: boolean;
  programs: any[];
  closefunction: any;
  constructor(private store: Store<any>, private appointments: AppointmentsService, private availabilityService:  AvailabilityService, private scheduleService:  ScheduleService, private notificationService: NotificationsService,private teacherService: TeachersService, private classService: SearchService, private authService: AuthService, private schoolService: SchoolService, private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog) { 
    this.activatedRoute.queryParams.subscribe(params => {      
      this.appointmentId = params['appointmentId'];
      this.isPopUpSession = params["popup"];
      let savedPopup = localStorage.getItem("popup");
      if(!this.appointmentId && !this.isPopUpSession && savedPopup) {
        this.appointmentId = savedPopup;
        this.isPopUpSession =  true;
      }
      if (this.appointmentId == undefined){
        return;
      }
      this.hasPermission = true;
      this.tutorId = params['tutorId'];
      if (this.tutorId == undefined){        
        return
      }

      let userId = localStorage.getItem('userId');            
      this.route = this.activatedRoute.snapshot;
      let schoolName = this.route.params['schoolID'];
      if (userId == null){   
        localStorage.setItem('appointmentId', this.appointmentId);
        localStorage.setItem('tutorId', this.tutorId);
        this.router.navigate(['']);          
        return;
      }
      if (this.tutorId != userId){
        this.hasPermission = false;
        return;
      }       
      if (this.allTeachers.length == 0){     
        this.getAppointments(); 
        setTimeout(() => {        
          if (schoolName == ShortSchoolName.mandarin){
            this.getMandarinAppointmentById()
  
          }
          else if (schoolName == ShortSchoolName.tempe_union_peer_tutoring){
            this.getTempeUnionAppointmentById()

          }
          else {
            this.getAppointmentById();
          }        
        }, 600);
        
      }      
    });
  }
  tokenRefresh(){
    this.authService.tokenRefresh().subscribe(data => {        
      localStorage.setItem('token', data['token']);
      this.getAppointments();

      setTimeout(() => {        
        this.showTokenRefreshDialog();
      }, 3000000); // Activate after 50 minutes.
      
    }, error => {
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })        
  }
  showTokenRefreshDialog() {    
    this.isRefreshToken = false;
    
    const dialogRef = this.dialog.open(TokenRefreshDialog, {
      width: '250px'      
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.isRefreshToken = true;
    });

    setTimeout(() => {
      if (!this.isRefreshToken){
        this.dialog.closeAll();
        this.logout();
        // this.router.navigate(['/school', this.schoolName, 'login']);         
        this.router.navigate(['']);    
      }
    }, 60000); // Activate after 15 seconds

  }  
  ngOnInit() {
    // let userStatus = localStorage.getItem('userStatus');    
    // if (userStatus == 'true'){
    //   this.isApproved = true;
    // }
    this.authService.getUserById(localStorage.getItem('userId')).subscribe(user =>{
      if (user['approved'])
      {
        this.isApproved = true
      }
    })

    localStorage.removeItem('appointmentId')
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];
    
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => {
      console.log('school isMaintenance', data.result['maintenance'])    
      this.isMaintenance = data.result['maintenance']         
      this.dashLogo = data.result['dashLogo']
      console.log('school isActive', data.result['active'])    
      this.isActive = data.result['active']
    })

    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);   
      this.logout()     
      return
    }

    if (this.schoolName == 'mandarin'){
      this.getMandarinTeachers();

    }
    else if (this.schoolName == 'tempe_union_peer_tutoring'){
      this.getTempeUnionTeachers();
    }
    else {
      this.getHamilonTeachers();
    }    
    //this.getAppointments();   

    this.tokenRefresh()
  }

  openPopUpSession() {
    console.log("opening popup")
    let appointmentData = this.upcommingSessions.find(data => data.appt._id == this.appointmentId)
    console.log(this.upcommingSessions)
    console.log(appointmentData.appt)
    this.router.navigate(['/school', this.schoolName, 'dashboard'],{ queryParams: { appointmentId: appointmentData.appt._id } } );
    localStorage.removeItem("popup")
    this.openSessionDetailDialog(appointmentData.appt);
  }

  openSelector(){
    if(this.selectorOpen != true){
      this.appointments.getAppointments().subscribe(data => {
          this.userData = data.result;
        })
      this.programs = []
      this.programs.push(this.userData.school)
      this.programs = this.programs.concat(this.userData.schools)
      this.selectorOpen = true
      this.closefunction = this.closeSelector.bind(this)
      setTimeout(()=>
      window.addEventListener('click', this.closefunction), 10)
    }
  }
  closeSelector(e:MouseEvent) {
    if(document.getElementById('program-selector')!=null){
    if(!document.getElementById('program-selector').contains(e.target as Node)) {
        console.log(this)
        this.selectorOpen = false
        console.log(this.selectorOpen)
        window.removeEventListener('click', this.closefunction)
    } }
  }
  switchSchools(school)
  {
    this.router.navigate(['school', school,'dashboard']).then(()=>
    window.location.reload())
  }

  getLocation(location) {
    let result = ""
    Object.keys(location).forEach(key => {
      if(location[key]) result = key
    })
    return result
  }
  
  acceptAppointment(){
    this.scheduleService.approveAppointment(this.userData._id,  this.appointmentId).subscribe( data =>{
      this.getAppointments()
      this.notificationService.success('Success', 'Thanks for accepting the session! Set up the event using the info in the request email.', {
        timeOut: 13000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    })
  }
  rejectAppointment(){
    this.scheduleService.rejectAppointment(this.userData._id, this.appointmentId).subscribe( data => {
      this.getAppointments()
      this.notificationService.success('Success', 'Appointment has been rejected successfully', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });

    }, error => {
      this.notificationService.error('Error', 'Unfortunately rejection has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  saveVideoCallLink(link){
    this.scheduleService.saveVideoCallLink(this.appointmentId, link).subscribe( data => {
      this.getAppointments()
      this.notificationService.success('Success', 'Video Call Link has been saved successfully!', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });

    }, error => {
      this.notificationService.error('Error', 'Something went wrong.', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  cancelAppointment(){    
    this.scheduleService.cancelAppointment(this.userData._id, this.appointmentId).subscribe( data => {
      this.getAppointments()
      this.notificationService.success('Success', 'Appointment has been canceled successfully', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });

    }, error => {
      this.notificationService.error('Error', 'Unfortunately cancellation has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }

    })

  }
  getAppointmentById(){    
    this.teacherService.getHamiltonTeachersJSON().subscribe(data => {      
      this.allTeachers = data;
      this.scheduleService.getAppointmentById(this.appointmentId).subscribe(data => {        
        if (this.loginError == null){
          this.openSessionDetailDialog(data.result['content'])
        }else {
          localStorage.setItem('appointmentId', this.appointmentId)
        }      
      }, error => {
        if (error.status == 401){
          this.logout();
          this.router.navigate(['']);  
        }
      })
    })    
    
  }
  getMandarinAppointmentById(){    
    this.teacherService.getMandarinTeachersJSON().subscribe(data => {      
      this.allTeachers = data;
      this.scheduleService.getAppointmentById(this.appointmentId).subscribe(data => {        
        if (this.loginError == null){
          this.openSessionDetailDialog(data.result['content'])
        }else {
          localStorage.setItem('appointmentId', this.appointmentId)
        }      
      }, error => {
        if (error.status == 401){
          this.logout();
          this.router.navigate(['']);  
        }
      })
    })        
  }
  getTempeUnionAppointmentById(){    
    this.teacherService.getTempeUnionTeachersJSON().subscribe(data => {      
      this.allTeachers = data;
      this.scheduleService.getAppointmentById(this.appointmentId).subscribe(data => {        
        if (this.loginError == null){
          this.openSessionDetailDialog(data.result['content'])
        }else {
          localStorage.setItem('appointmentId', this.appointmentId)
        }      
      }, error => {
        if (error.status == 401){
          this.logout();
          this.router.navigate(['']);  
        }
      })
    })        
  }
  getHamilonTeachers(){
    this.allTeachers = [];
    this.teacherService.getHamiltonTeachersJSON().subscribe(data => {      
      this.allTeachers = data;   
    })    
  }
  getMandarinTeachers(){
    this.allTeachers = [];
    this.teacherService.getMandarinTeachersJSON().subscribe(data => {      
      this.allTeachers = data;   
    })    
  }
  getTempeUnionTeachers(){
    this.allTeachers = [];
    this.teacherService.getTempeUnionTeachersJSON().subscribe(data => {      
      this.allTeachers = data;   
    })    
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    if(this.isPopUpSession) localStorage.setItem('popup', this.appointmentId);
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['']);  
  }
  
  getAppointments(){
    localStorage.setItem('schoolName', this.schoolName)
    this.appointments.getAppointments()
      .subscribe(data => {
        console.log("user data", data)       
        this.userData = data.result;
        this.isApproved = this.userData.approved        
        if (this.isApproved){
          localStorage.setItem('userStatus', 'true');
        }
        let short_schoolName = this.userData['school'].shortName;        
        let schoolNames = [];
        this.userData['schools'].forEach(school =>{
          schoolNames.push(school.shortName)
        })
      
        
        let isExist = schoolNames.includes(this.schoolName);                             
        if (short_schoolName != this.schoolName && !isExist){          
          this.notificationService.error('Error', 'You do not have access to this school.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
          this.logout();          
          this.router.navigate(['']);   
        }        
        this.schoolID = this.userData['school']._id;          
        if (this.schoolName == this.userData['school'].shortName){          
          this.schoolID = this.userData['school']._id;      
        }else {
          this.userData['schools'].forEach(school =>{
            if (this.schoolName == school.shortName){
              this.schoolID = school._id;                          
            }
          })
        }
        this.appointmentsList = this.userData['appointments'];   
        this.tutoringClasses = [];
        let tempTutoringClasses = []
        tempTutoringClasses = this.userData['courses'];      
        this.tutoringClasses = tempTutoringClasses.filter((course => course.school == this.schoolID))  

        let tempUpcommingSessions = [];
        let tempPastSessions = [];
        this.appointmentsList.forEach(appointment=>{
          if (appointment.appt != null && appointment.appt.status != 'blank' && appointment.appt.status != 'paused' && appointment.appt.status != 'declined' && appointment.appt.status != 'not available'){
            let rawDate = appointment.appt.date;
            appointment.dateFormat = moment(rawDate, 'MM-DD-YYYY').format("MM/DD");
            appointment.dayOfWeek = moment(rawDate, 'MM-DD-YYYY').format("ddd").toUpperCase();          
            let datestring = appointment.appt.year + '-' + String(appointment.dateFormat).split('/')[0] + '-' + String(appointment.dateFormat).split('/')[1] + ':' + appointment.appt.start            
            let start_timestamp = new Date(datestring).getTime()
            appointment.timestamp = start_timestamp;
            
            if (appointment.appt.tutee != null && appointment.appt.tutee._id == this.userData._id){
              appointment.userType = 'student'
            }else {
              appointment.userType = 'tutor'
            }          
            if (appointment.appt.tutor != null && appointment.appt.tutor._id == this.userData._id){            
              appointment.userType = 'tutor'
            }

            //10 minutes after start time, move to past session
            let current_timestamp = new Date().getTime() - 600000
            if (current_timestamp > start_timestamp){                        
              // update appointment only for sessions not already tutored and in the current school      
              if ((appointment.appt.tutor && appointment.appt.tutor._id == this.userData._id) && (!appointment.appt.tutored) && appointment.appt.school == this.schoolID){                             
                this.scheduleService.updateSessionStatus(appointment.appt._id).subscribe(result =>{
                })
                
              }
              tempPastSessions.push(appointment)
              // this.upcommingSessions.push(appointment)              
            }else {
              tempUpcommingSessions.push(appointment)              
            }            
          }                              
        })
        tempUpcommingSessions.sort((a, b) => a.timestamp - b.timestamp);
        this.upcommingSessions = tempUpcommingSessions.filter((session => session.appt.school == this.schoolID))
        console.log("upcommingSessions", this.upcommingSessions)
        if(this.isPopUpSession) {
          this.openPopUpSession()
        }
        
        tempPastSessions.sort((a, b) => b.timestamp - a.timestamp);        
        this.pastSessions = tempPastSessions.filter(session => session.appt.school == this.schoolID)        
        
        this.username = this.userData['firstname'] + ' ' + this.userData['lastname']                              
        this.getAvailabilityByID();                                  
      }, error => {                        
        if (error != null){        
          this.loginError  = error;       
          if (error.status == 401){
            this.logout();
            this.router.navigate(['']);  
          }          
        }
      })
  }
  getAvailabilityByID(){  
    this.tutorClassList = [];
    this.hours_classes = [];
    this.availabilityService.getAvailabilitiesByID(this.schoolID).subscribe(data =>{
      console.log("all available", data.result)
      this.myCourseList = data.result;         
      this.tutoringClasses.forEach(tutorClass => { 
        this.scheduleService.getTutoredHours(this.schoolID, this.userData._id, tutorClass._id).subscribe(data =>{  
          console.log("duration", data.result)      
          this.hours_classes.push(data.result)        
          if(this.hours_classes.length == this.tutorClassList.length){        
          }          
        })
        
        // let hours = tutorClass.tutoredHours;
        // if (hours == undefined){
        //   hours = 0;
        // }        
        let item = {
          id: tutorClass._id,
          classname:  tutorClass.name,
          hours: 0,
        }
        
        this.tutorClassList.push(item);
      })
    }, error => {
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }

  goToHomePage(){
    if (this.isApproved){
      this.router.navigate(['/school', this.schoolName, 'dashboard']);  
    }    
  }
  goToSearchPage(){    
    if (this.isApproved){
      window.analytics.track("Classes Tab Opened",{
      });
      this.router.navigate(['/school', this.schoolName, 'search']);  
    }    
  }
  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  
  showDetails(item) {    
    const dialogRef = this.dialog.open(DetailCourseDialog, {
      width: '250px',
      data: {dayOfWeek: item.dayOfWeek, location: item.location, startTime: item.start, endTime: item.end}
    });

    dialogRef.afterClosed().subscribe(result => {      
      
    });
  }

  upcommingSessionViewDetail(index){        
    this.router.navigate(['/school', this.schoolName, 'dashboard'],{ queryParams: { appointmentId: this.upcommingSessions[index].appt._id } } ); 
    this.appointmentId =  this.upcommingSessions[index].appt._id;
    this.openSessionDetailDialog(this.upcommingSessions[index].appt)
  }
  openSessionDetailDialog(appointmentData){
    let tutor_name = '';
    if (appointmentData.tutor != null){
      tutor_name = appointmentData.tutor.firstname + ' ' + appointmentData.tutor.lastname
    }
    let tutors = []
    if (appointmentData.tutors != null){
      tutors = appointmentData.tutors
    }else {
      tutors.push(tutor_name)
    }

    let student = ''
    if (appointmentData.tutee != null){
      student = appointmentData.tutee.firstname + ' ' + appointmentData.tutee.lastname;
    }
    
    let teacher = '';
    if (appointmentData.teacher != null){
      teacher = appointmentData.teacher;
    }    

    let teachers = [];        
    if (this.schoolName != ShortSchoolName.peersquared_provides_summer_tutoring && this.schoolName != ShortSchoolName.private_tutoring && this.schoolName != ShortSchoolName.brainstem_peer_tutoring){     
      teachers = this.allTeachers[appointmentData.course.name.trim()];      
      if (teachers != undefined &&  teachers.length == 0){
        teachers = this.allTeachers[appointmentData.course.name]        
      }
    }    

    let comment = ''
    if (appointmentData.comment != null){
      comment = appointmentData.comment;
    }

    let videoCallLink = ''
    let existCallLink = false;
    if (appointmentData.videoCallLink != ''){
      videoCallLink = appointmentData.videoCallLink;
      existCallLink = true;
    }

    if (appointmentData.tutor != null && appointmentData.tutor._id == this.userData._id){      
      appointmentData.userType = 'tutor'
    }else {
      appointmentData.userType = 'student'
    }

    let helps = ['Homework help', 'Test prep', 'Class content', 'Get organized', 'Other'];    
    
    const dialogRef = this.dialog.open(DetailSessionDialog, {      
      height: '500px',
      data: {id: appointmentData._id, status: appointmentData.status, student: student, teacher: teacher, teachers: teachers,  tutor: tutor_name, userType: appointmentData.userType,  tutors: tutors, help: appointmentData.help, helps: helps, comment: comment, date: appointmentData.date, time: appointmentData.start + ' - ' + appointmentData.end, classname: appointmentData.course.name, location: appointmentData.location, fileList: appointmentData.fileList, videoCallLink: videoCallLink, existCallLink: existCallLink}      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined || result != null){
        let data = result.event;        
        if (data.status == 'accept'){
          this.acceptAppointment()
        }else if (data.status == 'reject') {
          this.rejectAppointment()
        } else if (data.status == 'cancel'){
          this.cancelAppointment()
        } else if (data.videoCallLink != ''){
          this.saveVideoCallLink(data.videoCallLink)
        }
      } else {
        this.getAppointments()
      }  
    });
  }

  pastSessionViewDetailNote(index){            
    this.appointmentId =  this.pastSessions[index].appt._id;
    this.openDetailNoteDialog(this.pastSessions[index].appt)
  }

  openDetailNoteDialog(appointmentData){    
    let tutor_name = '';
    if (appointmentData.tutor != null){
      tutor_name = appointmentData.tutor.firstname + ' ' + appointmentData.tutor.lastname
    }
    let tutors = []
    if (appointmentData.tutors != null){
      tutors = appointmentData.tutors
    }else {
      tutors.push(tutor_name)
    }
    
    let teacher = '';
    if (appointmentData.teacher != null){
      teacher = appointmentData.teacher;
    }
    let teachers = [];
    teachers = this.allTeachers[appointmentData.course.name.trim()];    
    if (teachers != undefined && teachers.length == 0){
      teachers = this.allTeachers[appointmentData.course.name]
    }
    let comment = ''
    if (appointmentData.comment != null){
      comment = appointmentData.comment;
    }

    let student = ''
    if (appointmentData.tutee != null){
      student = appointmentData.tutee.firstname + ' ' + appointmentData.tutee.lastname;
    }

    if (appointmentData.tutor != null && appointmentData.tutor._id == this.userData._id){      
      appointmentData.userType = 'tutor'
    }else {
      appointmentData.userType = 'student'
    }

    let helps = ['Homework help', 'Test prep', 'Class content', 'Get organized', 'Other'];

    let fileList = [];
    appointmentData.fileList.forEach(file=>{
      let split_url = file.split('/')
      let filename = split_url[split_url.length - 1];
      let item = {
        filename: filename,
        link: file
      }
      fileList.push(item)
    })

    let notes = ''
    if (appointmentData.notes != null){
      notes = appointmentData.notes;
    }

    let noShowStatus = false
    console.log("status: " + appointmentData.status)
    if (appointmentData.status == "no show"){
      console.log(noShowStatus);
      noShowStatus = true;
    }
    console.log(noShowStatus);

    let tutorFileList = [];
    if (appointmentData.tutorFileList != null){
      tutorFileList = appointmentData.tutorFileList;
    }
    
    let tutorFileNameList = [];
    tutorFileList.forEach(file=>{
      let split_url = file.split('/')
      let filename = split_url[split_url.length - 1];      
      tutorFileNameList.push(filename)
    })
    
    const dialogRef = this.dialog.open(DetailNoteDialog, {      
      height: '600px',
      data: {status: appointmentData.status, student: student, teacher: teacher, teachers: teachers,  tutor: tutor_name, userType: appointmentData.userType,  tutors: tutors, help: appointmentData.help, helps: helps, comment: comment, date: appointmentData.date, time: appointmentData.start + ' - ' + appointmentData.end, classname: appointmentData.course.name, location: appointmentData.location, fileList: fileList, tutorFileNameList: tutorFileNameList, notes: notes, noShowStatus: noShowStatus, tutorFileList: tutorFileList}
    });
    

    dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined || result != null){
        let data = result.event;                
        this.saveNotes(data.notes, data.fileList, data.noShowStatus)
      }        
    });
  }

  saveNotes(notes, fileList, noShowStatus){
    this.scheduleService.saveNotes(this.appointmentId, notes, fileList, noShowStatus).subscribe( data =>{
      this.getAppointments()
      this.notificationService.success('Success', 'Notes has been saved successfully', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  showStudentFeedbackDialog(index) {  
    this.appointmentId =  this.pastSessions[index].appt._id;
    let studentFeedback = this.pastSessions[index].appt.studentFeedback;
    let student_status = this.pastSessions[index].appt.student_status;
    let data = {firstValue: 0, secondValue: 0, thirdValue: 0, status: "pending", not_tutored: false};
    if (studentFeedback.length != 0){
      data = {firstValue: studentFeedback[0], secondValue: studentFeedback[1], thirdValue: studentFeedback[2], status: student_status, not_tutored: !this.pastSessions[index].appt.tutored};
    }
    const dialogRef = this.dialog.open(StudentFeedbackDialog, {width: '400px',
    data: data
    });
  
    dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined || result != null){
        let data = result.event;                
        let studentFeedback = [data.firstValue, data.secondValue, data.thirdValue]      
        let tutored = data.tutored
        this.saveStudentFeedback(studentFeedback, tutored)
      }
    });
  }

  saveStudentFeedback(studentFeedback, tutored){    
    this.scheduleService.saveStudentFeedback(this.appointmentId, studentFeedback, tutored).subscribe( data =>{
      this.getAppointments()
      this.notificationService.success('Success', 'Feedback has been saved successfully', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  showTutorFeedbackDialog(index) {  
    this.appointmentId =  this.pastSessions[index].appt._id;             
    let helps_status = [
      {name: 'homework', value: false}, 
      {name: 'concepts_content', value: false}, 
      {name: 'learning_strategy', value: false},
      {name: 'emotional_support', value: false}, 
      {name: 'other', value: false}, 
    ]
    let tutorFeedback = this.pastSessions[index].appt.tutorFeedback;
    if (tutorFeedback.length != 0){
      helps_status = tutorFeedback.helps_status;
    }

    let status = this.pastSessions[index].appt.tutor_status;
    

    const dialogRef = this.dialog.open(TutorFeedbackDialog, {width: '400px',
    data: {helps_status: helps_status, secondValue: tutorFeedback.second_rate, thirdValue: tutorFeedback.third_rate, status: status}   
    });
  
    dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined || result != null){
        let data = result.event;                
        let tutorFeedback = {
          helps_status: data.helps_status,
          second_rate: data.secondValue,
          third_rate: data.thirdValue
        }                
        this.saveTutorFeedback(tutorFeedback)
      }
    });
  }

  saveTutorFeedback(tutorFeedback){
    this.scheduleService.saveTutorFeedback(this.appointmentId, tutorFeedback).subscribe( data =>{
      this.getAppointments()
      this.notificationService.success('Success', 'Feedback has been saved successfully', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })
  }
  handleAvailability(id, isDeleted, isPaused){
    if (isDeleted){
      this.availabilityService.deleteAvailability(id, this.availabilities_count).subscribe(data =>{
        this.getAppointments()        
        this.notificationService.success('Success', `You are no longer available on this availability`, {
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        });
      }, error => {
        this.notificationService.error('Error', 'Unfortunately deletion has been failed', {
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        });
        if (error.status == 401){
          this.logout();
          this.router.navigate(['']);  
        }
      })  
    }else {
      this.availabilityService.updateAvailability(id, isPaused).subscribe(data =>{
        this.getAvailabilityByID()
        this.notificationService.success('Success', `Availability status has been changed successfully`, {
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        });
      }, error =>{
        this.notificationService.error('Error', 'Unfortunately updation has been failed', {
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        });
      })
      
    }
    
  }
  openDeleteAvailabilityDialog(availability, availabilities_count){  
    this.availabilities_count = availabilities_count
    let existing_sessions: any
    this.scheduleService.getExistingSessions(availability.id).subscribe(data =>{
      existing_sessions = data.result
      const dialogRef = this.dialog.open(DeleteAvailabilityDialog, {width: '500px',
      data: {availability: availability, existing_sessions: existing_sessions}   
      });
      dialogRef.afterClosed().subscribe(result => {      
        if (result != undefined || result != null){
          let data = result.event;                        
          this.handleAvailability(data.id, data.isDeleted, data.isPaused)
        }
      });
    })    
  }

  openDeleteTutoringDialog(classData){
    const dialogRef = this.dialog.open(DeleteTutoringDialog, {width: '500px',
    data: {classData: classData}   
    });
    dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined || result != null){
        let data = result.event;
        this.classService.deleteClassByID(data.id).subscribe(data => {
          this.getAppointments()
          this.notificationService.success('Success', `Class has been deleted successfully`, {
            timeOut: 4000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        })
      }
    });
  }

  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
}


@Component({
  selector: 'token-refresh-dialog',
  templateUrl: 'token-refresh-dialog.html',
})
export class TokenRefreshDialog {

  constructor(
    public dialogRef: MatDialogRef<TokenRefreshDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = true;
    }

  onClose(): void {
    this.dialogRef.close();
  }
  refreshToken(){
    this.dialogRef.close();
  }

}
@Component({
  selector: 'cancel-confirm-dialog',
  templateUrl: 'cancel-confirm-dialog.html',
})
export class CancelConfirmDialog {

  constructor(    
    public dialogRef: MatDialogRef<CancelConfirmDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    }

  onNo(): void {
    let data = {
      status: false,    
    }    
    this.dialogRef.close({event: data});
  }
  onYes(){
    let data = {
      status: true,    
    }    
    this.dialogRef.close({event: data});    
  }

}

@Component({
  selector: 'detail-course-dialog',
  templateUrl: 'detail-course-dialog.html',
})
export class DetailCourseDialog {

  constructor(
    public dialogRef: MatDialogRef<DetailCourseDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClose(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'student-feedback-dialog',
  templateUrl: 'student-feedback.dialog.html',
  styleUrls: ['student-feedback-dialog.css']
})
export class StudentFeedbackDialog {  
  private firstValue: string = '0';
  private secondValue: string = '0';
  private thirdValue: string = '0';
  private not_tutored: boolean = false
  constructor(
    public dialogRef: MatDialogRef<StudentFeedbackDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FeedBackDialogData) {      
      this.not_tutored = data.not_tutored
    }

  onClose(): void {
    this.dialogRef.close();
  }
  onRateFristValue(event){    
    this.firstValue = event;    
  }
  onRateSecondValue(event){    
    this.secondValue = event;    
  }
  onRateThirdValue(event){    
    this.thirdValue = event;    
  }
  onSave(){
    let data = {
      firstValue: this.firstValue,
      secondValue: this.secondValue,
      thirdValue: this.thirdValue,
      tutored: !this.not_tutored
    }    
    this.dialogRef.close({event: data});
  }
}

@Component({
  selector: 'tutor-feedback-dialog',
  templateUrl: 'tutor-feedback.dialog.html',
  styleUrls: ['tutor-feedback-dialog.css']
})
export class TutorFeedbackDialog {  
  private homework: boolean;
  private concepts_content: boolean;
  private learning_strategy: boolean;
  private emotional_support: boolean;
  private other: boolean;
  private secondValue: string = '0';
  private thirdValue: string = '0';
  constructor(
    public dialogRef: MatDialogRef<TutorFeedbackDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TutorFeedBackDialogData) {      
      if (data.helps_status.length != 0){
        this.homework = data.helps_status[0].value;
        this.concepts_content = data.helps_status[1].value;
        this.learning_strategy = data.helps_status[2].value;
        this.emotional_support = data.helps_status[3].value;
        this.other = data.helps_status[4].value;       
      }      
    }

  onClose(): void {
    this.dialogRef.close();
  }  
  onRateSecondValue(event){    
    this.secondValue = event;    
  }
  onRateThirdValue(event){    
    this.thirdValue = event;    
  }
  
  onSave(){
    let helps_status = [
      {name: 'homework', value: this.homework}, 
      {name: 'concepts_content', value: this.concepts_content}, 
      {name: 'learning_strategy', value: this.learning_strategy},
      {name: 'emotional_support', value: this.emotional_support}, 
      {name: 'other', value: this.other}, 
    ]
    let data = {      
      helps_status: helps_status,
      secondValue: this.secondValue,
      thirdValue: this.thirdValue
    }    
    this.dialogRef.close({event: data});
  }
}

@Component({
  selector: 'delete-availability-dialog',
  templateUrl: 'delete-availability-dialog.html',
  styleUrls: ['delete-availability-dialog.css']
})
export class DeleteAvailabilityDialog {    
  private isPaused: boolean = false
  private isDeleted: boolean = false
  private id: string = null
  constructor(
    public dialogRef: MatDialogRef<DeleteAvailabilityDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: any) {       
      this.isPaused = data.availability.paused      
      this.id = data.availability.id
      console.log('DeleteAvailabilityDialog existing_sessions',data.existing_sessions)
      console.log('DeleteAvailabilityDialog availability',data.availability)
    }

  onClose(): void {
    this.dialogRef.close();
  }  
  
  onSave(){    
    let data = {
      id: this.id,
      isPaused: this.isPaused,
      isDeleted:  this.isDeleted
    }
    this.dialogRef.close({event: data});
  }
}

@Component({
  selector: 'delete-tutoring-dialog',
  templateUrl: 'delete-tutoring-dialog.html',
  styleUrls: ['delete-tutoring-dialog.css']
})
export class DeleteTutoringDialog { 
  private id: string = null
  constructor(
    public dialogRef: MatDialogRef<DeleteTutoringDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: any) {    
      this.id = data.classData.id
      console.log('DeleteTutoringDialog',data.classData)
    }

  onClose(): void {
    this.dialogRef.close();
  }  
  
  onSave(){    
    let data = {
      id: this.id,
    }
    this.dialogRef.close({event: data});
  }
}

@Component({
  selector: 'help-dialog',
  templateUrl: 'help-dialog.html',
  styleUrls: ['help-dialog.css']
})
export class HelpDialog { 
  constructor(
    public dialogRef: MatDialogRef<HelpDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: any) {  
      console.log('HelpDialog',data)
    }

  onClose(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'detail-session-dialog',
  templateUrl: 'detail-session-dialog.html',
  styleUrls: ['detail-session-dialog.css']
})
export class DetailSessionDialog implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  private location = ""
  private isDisableComment = true
  private isDisableFiles = true
  private draftComment = ""
  private uploader: FileUploader;
  private fileName: string;
  private fileURL: string;
  private fileItem: any;
  private fileList: any[];

  constructor(
    private cloudinary: Cloudinary,
    private scheduleService:  ScheduleService,
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetailSessionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SessionDialogData
  ) {
    this.location = ""
    this.isDisableComment = true
    this.isDisableFiles = true
    this.draftComment = ""
    this.fileName = '';
    this.fileURL = '';
  }

  ngOnInit() {
    console.log(this.data)
    Object.keys(this.data.location).forEach(key => {
      if(this.data.location[key]) this.location = key
    })
    this.draftComment = this.data.comment

    this.fileList = [];
    this.data.fileList.forEach(file=>{
      let split_url = file.split('/')
      let filename = split_url[split_url.length - 1];
      let item = {
        filename: filename,
        link: file
      }
      this.fileList.push(item)
    })

    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
      // Upload files automatically upon addition to upload queue
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {    
      form.append('upload_preset', this.cloudinary.config().upload_preset);      
      form.append('file', fileItem);
      this.fileItem = fileItem;
      this.fileName = this.fileItem.file.name
      fileItem.withCredentials = false;
      return { fileItem, form };
    };
    this.uploader.onSuccessItem = (item: any, response: string): any => {
      this.blockUI.stop()
      this.fileURL = JSON.parse(response).secure_url;
      let split_url = this.fileURL.split('/')
      let filename = split_url[split_url.length - 1];

      let fileItem = {
        filename: filename,
        link: this.fileURL
      }
      this.fileList.push(fileItem);
      this.data.fileList.push(this.fileURL);

      this.updateFileList();
    }    
    this.uploader.onErrorItem = (item: any, response: string): any => {}    
    this.uploader.onProgressItem = (item: any, progress: any): any => {}
    this.uploader.onBeforeUploadItem = (item: any): any => {
      this.blockUI.start()      
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  onAccept(){    
    let data = {
      status: 'accept',
    }
    this.dialogRef.close({event: data});
  }
  onReject(){    
    let data = {
      status: 'reject',
    }
    this.dialogRef.close({event: data});
  }
  onCancel(){
    const dialogRef = this.dialog.open(CancelConfirmDialog, {});    

    dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined || result != null){
        let data = result.event;      
        if (data.status) {
          let final_data = {
            status: 'cancel',
          }
          this.dialogRef.close({event: final_data});
        }else {
          this.dialogRef.close();
        }        
      }              
    });
  }
  onSaveVideoCallLink(link){
    let substring = link.substring(0, 4);
    let data = {
        videoCallLink: link,
    }
    if (substring != "http"){
      link = "https://" + link;
      data = {
      videoCallLink: link,
      }          
    }
    this.dialogRef.close({event: data});
  }

  editComment() {
    this.isDisableComment = false;
  }

  updateFiles() {
    this.isDisableFiles = false;
  }

  cancelComment() {
    this.data.comment = this.draftComment
    this.isDisableComment = true;
  }

  saveComment() {
    this.scheduleService.updateComment(this.data.id, this.data.comment).subscribe( data =>{
      this.draftComment = this.data.comment
      this.isDisableComment = true;
      this.notificationService.success('Success', 'Your comment updated successfully.', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    })
  }

  upload() {
    if (this.data.fileList.length < 3){
      $('#upload_input').click();    
    }    
  }

  removeFile(index) {
    this.fileList.splice(index, 1);
    this.data.fileList.splice(index, 1);
    this.updateFileList();
  }

  updateFileList() {
    this.scheduleService.updateFiles(this.data.id, this.data.fileList).subscribe( data =>{
      this.notificationService.success('Success', 'Your fileList updated successfully.', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }, error => {
      this.notificationService.error('Error', 'Unfortunately has been failed', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    })
  }
}

@Component({
  selector: 'detail-note-dialog',
  templateUrl: 'detail-note-dialog.html',
  styleUrls: ['detail-note-dialog.css']
})
export class DetailNoteDialog  implements OnInit{
  @BlockUI() blockUI: NgBlockUI;
  @Input()  
  private hasBaseDropZoneOver: boolean = false;
  private uploader: FileUploader;  
  private fileName: string;
  private fileURL: string;
  private fileItem: any;
  private fileList = [];
  private fileNameList = [];
  private files = [];
  private noShowStatus: boolean;
  private notes: string;
  private isEmptyNote: boolean;

  constructor(
    private cloudinary: Cloudinary,
    private zone: NgZone,
    private http: HttpClient,
    public dialogRef: MatDialogRef<DetailNoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SessionDialogData) {      
      this.fileName = '';
      this.fileURL = '';      
      this.isEmptyNote = false;      
      this.files = [];
      this.fileList = data.tutorFileList;
      this.fileNameList = data.tutorFileNameList;
      for (let i = 0; i < this.fileList.length; i++){
        let item = {
          name: this.fileNameList[i],
          link: this.fileList[i]
        }
        this.files.push(item)
      }
      this.noShowStatus = data.noShowStatus;
      this.notes = data.notes;
    }
    ngOnInit(){
      const uploaderOptions: FileUploaderOptions = {
        url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
        // Upload files automatically upon addition to upload queue
        autoUpload: true,
        // Use xhrTransport in favor of iframeTransport
        isHTML5: true,
        // Calculate progress independently for each uploaded file
        removeAfterUpload: true,
        // XHR request headers
        headers: [
          {
            name: 'X-Requested-With',
            value: 'XMLHttpRequest'
          }
        ]
      };
  
      this.uploader = new FileUploader(uploaderOptions);
  
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {    
        form.append('upload_preset', this.cloudinary.config().upload_preset);      
        form.append('file', fileItem);
        this.fileItem = fileItem;
        this.fileName = this.fileItem.file.name
        fileItem.withCredentials = false;
        return { fileItem, form };
      };
      this.uploader.onSuccessItem = (item: any, response: string): any => {            
        this.blockUI.stop()
        this.fileURL = JSON.parse(response).secure_url;
        this.fileList.push(this.fileURL);
        let split_url = this.fileURL.split('/')
  
        let filename = split_url[split_url.length - 1];
        this.fileNameList.push(filename)              
        this.files = [];
        for (let i = 0; i < this.fileList.length; i++){
          let item = {
            name: this.fileNameList[i],
            link: this.fileList[i]
          }
          this.files.push(item)
        }        
      }    
      this.uploader.onErrorItem = (item: any, response: string): any => {      
  
      }
      
      this.uploader.onProgressItem = (item: any, progress: any): any => {
        
      }
      this.uploader.onBeforeUploadItem = (item: any): any => {
        this.blockUI.start()
        
      }
    }    
    fileOverBase(e: any): void {
      this.hasBaseDropZoneOver = e;
    }

    onClose(): void {
      this.dialogRef.close();
    }
    upload() {      
      if (this.fileNameList.length < 3){        
        $('#upload_files').click();    
      }    
    }
    onSubmit(){    
      this.isEmptyNote = false;
      if (this.notes.length == 0){
        this.isEmptyNote = true;
        return
      }
      let data = {
        notes: this.notes,
        fileList:  this.fileList,
        noShowStatus: this.noShowStatus
      }
      this.dialogRef.close({event: data});
    }    
}