import { NgModule } from '@angular/core';
import { Routes, RouterModule, GuardsCheckEnd } from '@angular/router';
import { DashboardPageComponent } from './components/pages/dashboard/dashboard-page/dashboard-page.component';
import { SearchPageComponent } from './components/pages/search/search-page/search-page.component';
import { ClassPageComponent } from './components/pages/class/class-page/class-page.component';
import { TeacherDashboardPageComponent } from './components/pages/teacher/dashboard/teacher-dashboard-page.component';
import { ParentApprovalPageComponent } from './components/pages/parent-approval/parent-approval-page.component';
/*complete this line*/
import {ProfileComponent} from './components/pages/profile/profile.component';
import { AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import { DataGuardService as DataGuard} from './data/data-guard.service';
import { LoginComponent } from './components/pages/login/login/login.component';
import { TypeSelectComponent } from './components/pages/onboarding/type-select/type-select.component';
import { SchoolInfoComponent } from './components/pages/onboarding/school-info/school-info.component';
import { PersonalInfoComponent } from './components/pages/onboarding/personal-info/personal-info.component';
import { ClassesComponent } from './components/pages/onboarding/classes/classes.component';
import { AboutMeComponent } from './components/pages/onboarding/about-me/about-me.component';
import { AddPictureComponent } from './components/pages/onboarding/add-picture/add-picture.component';
import { SignupConfirmationComponent } from './components/pages/onboarding/signup-confirmation/signup-confirmation.component';
import { VerificationConfirmationComponent } from './components/pages/onboarding/verification-confirmation/verification-confirmation.component';
import { PasswordResetComponent } from './components/pages/password-reset/password-reset.component';
import { TutorClassesComponent } from './components/pages/tutor-onboarding/tutor-classes/tutor-classes.component';
import { AvailabilitiesComponent } from './components/pages/tutor-onboarding/availabilities/availabilities.component';
import { WarningGuard } from './components/pages/tutor-onboarding/warning/warning';
import { TutorRecommendationComponent } from './components/pages/onboarding/tutor-recommendation/tutor-recommendation.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [DataGuard],
    pathMatch: 'full'
  },
  {
    path: 'password-reset', 
    component: PasswordResetComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'school/:schoolId',
    component: LoginComponent,
    canActivate: [DataGuard],
    pathMatch: 'full'
  },
  {
    path: 'onboarding/type-select', 
    component: TypeSelectComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard],    
  },
  {
    path: 'onboarding/school-info', 
    component: SchoolInfoComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard],    
  },
  {
    path: 'onboarding/personal-info', 
    component: PersonalInfoComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard],    
  },
  {
    path: 'onboarding/classes', 
    component: ClassesComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard],    
  },
  {
    path: 'onboarding/about-me', 
    component: AboutMeComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'onboarding/add-picture', 
    component: AddPictureComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'onboarding/tutor-recommendation', 
    component: TutorRecommendationComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'onboarding/signup-confirmation', 
    component: SignupConfirmationComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'onboarding/verification-confirmation', 
    component: VerificationConfirmationComponent, 
    pathMatch: 'full',
    canActivate: [DataGuard], 
  },
  {
    path: 'school/:schoolID/parentapproval', 
    component: ParentApprovalPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'school/:schoolID/dashboard', 
    component: DashboardPageComponent,
    canActivate: [DataGuard],
    data:{
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/search', 
    component: SearchPageComponent,     
    canActivate: [DataGuard],    
    data:{
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/class/:classID',
    component: ClassPageComponent,    
    canActivate: [DataGuard],    
    data: {
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/profile',
    component: ProfileComponent,
    canActivate: [DataGuard],
    data: {
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/tutor-onboarding/availabilities',
    component: AvailabilitiesComponent,
    canActivate: [DataGuard],
    canDeactivate: [WarningGuard],
    data: {
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/tutor-onboarding/classes',
    component: TutorClassesComponent,
    canActivate: [DataGuard],
    canDeactivate: [WarningGuard],
    data: {
      authRoute: false
    }
  },
  {
    path: 'school/:schoolID/teacher/dashboard', 
    component: TeacherDashboardPageComponent,
    canActivate: [DataGuard],
    data:{
      authRoute: false
    }
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
