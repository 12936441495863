import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { SearchService } from 'src/app/store/services/search.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {Store, select} from '@ngrx/store';
import { logout } from 'src/app/store/actions/auth.actions';
import { AuthService } from 'src/app/store/services/auth.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { ClassDetailService } from 'src/app/store/services/classDetail.service';
import { NotificationsService } from 'angular2-notifications'
import { capitalize} from 'src/app/common'
import {MatDialog} from '@angular/material/dialog';
import { HelpDialog} from '../../dashboard/dashboard-page/dashboard-page.component';
import { openJoinDialog } from '../../join/join-dialog';
@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit {

  classes: any = [];
  cloneClasses: any = [];
  selectCategory = 'All';
  categories = ['All Subjects', 'K-8 Subjects', 'Math', 'English', 'Science', 'Social Studies', 'World Language',  'Other']
  schoolName = '';
  dashLogo = '';
  schoolID = '';
  username = '';
  userData = null;
  route: ActivatedRouteSnapshot
  isMaintenance = false;  
  selectorOpen: boolean;
  programs: any[];
  closefunction: any;
  constructor(private classDetailService: ClassDetailService, private store: Store<any>,private router: Router,private userService: AppointmentsService, private schoolService: SchoolService, private searchService: SearchService, private activatedRoute: ActivatedRoute, private authService: AuthService, private notificationService: NotificationsService, public dialog: MatDialog) { }

  openJoinDialog = openJoinDialog
  ngOnInit() {
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => {      
      this.isMaintenance = data.result['maintenance']
      this.dashLogo = data.result['dashLogo']
    })

    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.goLoginPage();
      return
    }
    
    localStorage.removeItem('destLink')
    this.userService.getAppointments()
      .subscribe(data => {
        this.userData = data.result;
        // this.isMaintenance = this.userData.school.maintenance                        
        this.username = this.userData['firstname'] + ' ' + this.userData['lastname']
        this.schoolID = this.userData['school']._id;
        let short_schoolName = this.userData['school'].shortName;        
        let schoolNames = [];
        this.userData['schools'].forEach(school =>{
          schoolNames.push(school.shortName)
        })

        let isExist = schoolNames.includes(this.schoolName);                             

        if (this.schoolName == this.userData['school'].shortName){          
          this.schoolID = this.userData['school']._id;      
        }else {
          this.userData['schools'].forEach(school =>{
            if (this.schoolName == school.shortName){
              this.schoolID = school._id;                          
            }
          })
        }

        if (short_schoolName != this.schoolName && !isExist){
          this.notificationService.error('Error', 'You do not have access to this school.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
          this.logout();
          this.router.navigate(['/school', this.schoolName, 'join']);   
        }

        this.searchService.getAllClasses(this.schoolID).subscribe(data => {
          this.classes = data.result;
          console.log('class data', this.classes)
          this.cloneClasses = Object.assign([], this.classes)          
        })
    }, error => {
      if (error.error == 'Unauthorized'){
        this.goLoginPage();
      }
    })        
  }

  openSelector(){
    if(this.selectorOpen != true){
      this.userService.getAppointments().subscribe(data => {
          this.userData = data.result;
        })
      this.programs = []
      this.programs.push(this.userData.school)
      this.programs = this.programs.concat(this.userData.schools)
      this.selectorOpen = true
      this.closefunction = this.closeSelector.bind(this)
      setTimeout(()=>
      window.addEventListener('click', this.closefunction), 10)
    }
  }
  closeSelector(e:MouseEvent) {
    if(document.getElementById('program-selector')!=null){
    if(!document.getElementById('program-selector').contains(e.target as Node)) {
        console.log(this)
        this.selectorOpen = false
        console.log(this.selectorOpen)
        window.removeEventListener('click', this.closefunction)
    } }
  }
  switchSchools(school)
  {
    this.router.navigate(['school', school,'search']).then(()=>
    window.location.reload())
  }

  navigateClassWithQuery(className: String){
    this.router.navigate(["/class", className]);
  }
  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }
  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }
  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['/school', this.schoolName, 'login']);  
    localStorage.setItem('destLink', 'search')
  }
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }
  onCategorySelection(){        
    if (this.selectCategory == 'All Subjects'){
      this.classes = this.cloneClasses;
    }else {
      let newClasses: any = []
      newClasses = this.cloneClasses.filter(c=>c.category == this.selectCategory)
      this.classes = newClasses;     
    }        
  }
  gotoDetailClass(id){
    this.classDetailService.getClassByID(id).subscribe( data => {
      let classData = data.result['content'];
      window.analytics.track("Subject Opened",{
        subject: classData
      });
      

    });
    this.router.navigate(['/school', this.schoolName, 'class', id]);  
  }

  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
}
