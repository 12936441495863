import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
const helper = new JwtHelperService();
@Injectable({
    providedIn: 'root'
})
export class AuthService{
    private jwtHelper: JwtHelperService
    constructor (private http: HttpClient){
        this.jwtHelper = new JwtHelperService();
    }
    tokenRefresh() {        
        let token = localStorage.getItem('token');        
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(`${environment.backend}/api/users/token/refresh`,httpConfig);
    }
    loginServer(email: string, password: string, remember: boolean) {        
        return this.http.post(`${environment.backend}/api/users/login`, { email, password, remember })
    }
    joinServer(email: string, schoolId: string, code: string) {        
        return this.http.post(`${environment.backend}/api/users/join`, { email, schoolId, code })
    }
    deletUserById(userId: string) {        
        return this.http.post(`${environment.backend}/api/users/delete/${userId}`, { })
    }
    approveUser(userId: string) {        
        return this.http.post(`${environment.backend}/api/users/parent-approve/${userId}`, { })
    }
    getUserById(userId: string) {        
        return this.http.get(`${environment.backend}/api/users/${userId}`)
    }
    registerServer(school: string, email: string, personalEmail:string,  password: string){        
        return this.http.post(`${environment.backend}/api/users/register`, {school, email, personalEmail, password});
    }
    emailExists(email: string){        
        return this.http.post(`${environment.backend}/api/users/emailExists`, {email});
    }
    profileCreationServer(firstName: string, lastName: string, schoolName: string, birthday: string, gender: string, enableSMS: boolean, phoneNumber: string, gradeLevel: number, studentId: string, referrer: string, personalEmail: string, parentEmail: string, enableParentEmail: boolean,  token: string){        
        return this.http.post(`${environment.backend}/api/users/profile/` + token, {firstName, lastName, schoolName, birthday, gender, enableSMS, phoneNumber, gradeLevel, studentId, referrer, personalEmail, parentEmail, enableParentEmail});
    }
    teacherProfileCreationServer(firstName: string, lastName: string, carrier: string, enableSMS: boolean, phoneNumber: string, alternateEmail: string,  token: string){    
        return this.http.post(`${environment.backend}/api/users/teacher/profile/` + token, {firstName, lastName,carrier, enableSMS, phoneNumber,alternateEmail});
    }
    parseToken(token: string){
        let decoded = this.jwtHelper.decodeToken(token);
        return decoded;
    }
    storeToken(token: string){
        localStorage.setItem('token', token);
    }
    retrieveToken(){
        return localStorage.getItem('token');
    }
    removeToken(){
        if (localStorage.getItem('token') !== null){
            localStorage.removeItem('token');
        }
    }
    isAuthenticated(){
        const token = localStorage.getItem('token');
        console.log(token)
        if (token === null){
            console.log('null')
            return false;
        }
        try{
            this.jwtHelper.decodeToken(token);
        }catch (e){
            console.log('test')
            return false;
        }
        if(this.jwtHelper.isTokenExpired(token))
        {
            console.log("token expired")
        }
        return !this.jwtHelper.isTokenExpired(token);

    }
    sendEmail(userId){
        return this.http.post(`${environment.backend}/api/users/sendEmail`, { userId: userId })
    }
    sendPasswordResetEmail(email){
        return this.http.post(`${environment.backend}/api/users/sendPasswordResetEmail`, { email: email })
    }
    resetPassword(userId, resetCode, password){
        return this.http.post(`${environment.backend}/api/users/resetPassword`, { userId: userId, resetCode: resetCode, password:password })
    }
    sendParentEmail(userId){
        return this.http.post(`${environment.backend}/api/users/sendParentEmail`, { userId: userId })
    }
    sendParentInformation(userId){
        return this.http.post(`${environment.backend}/api/users/sendParentInformation`, { userId: userId })
    }
    sendParentConsent(userId){
        return this.http.post(`${environment.backend}/api/users/sendParentConsent`, { userId: userId })
    }
    confirmUser(userId, verificationCode){
        return this.http.post(`${environment.backend}/api/users/confirmUser`, {userId: userId, verificationCode: verificationCode})
    }
    update(userId, update){
        let token = localStorage.getItem('token');        
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };
        console.log({ userId: userId, update: update})
        return this.http.post(`${environment.backend}/api/users/update`, { userId: userId, update: update}, httpConfig)
    }
}