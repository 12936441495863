import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { SearchService } from 'src/app/store/services/search.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {Store, select} from '@ngrx/store';
import { logout } from 'src/app/store/actions/auth.actions';
import { AuthService } from 'src/app/store/services/auth.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { NotificationsService } from 'angular2-notifications'
import { capitalize} from 'src/app/common'
@Component({
  selector: 'parent-approval-page',
  templateUrl: './parent-approval-page.component.html',
  styleUrls: ['./parent-approval-page.component.css']
})
export class ParentApprovalPageComponent implements OnInit {
  schoolName = '';
  schoolID = '';
  username = '';
  userData = null;
  route: ActivatedRouteSnapshot
  isMaintenance = false;  
  checkedTermsPolicy: boolean = false
  requireTermsPolicy: boolean = false
  token: string = '';
  userId: string = ''
  constructor(private store: Store<any>,private router: Router, private schoolService: SchoolService, private activatedRoute: ActivatedRoute, private authService: AuthService, private notificationService: NotificationsService) { 
    this.activatedRoute.queryParams.subscribe(params => {      
      // this.token = params['token'];  
      // this.authService.storeToken(this.token)      
      this.userId = params['userId'];           
      // if (this.token == undefined ||this.userId == undefined){
      //   this.router.navigate(['/']);          
      // }
      // let isAuthenticated = this.authService.isAuthenticated()         
      // console.log('isAuthenticated',isAuthenticated)
      // if (!isAuthenticated){
      //   this.router.navigate(['/']);
      // }
    })
  }


  ngOnInit() {
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];                
  }  
  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }
  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['/', this.schoolName]);      
  }
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');            
    this.store.dispatch(logout());          
    this.router.navigate(['/', this.schoolName]);  
  }  
  onFinish(){
    this.requireTermsPolicy = false
    if (!this.checkedTermsPolicy){
      this.requireTermsPolicy = true
      return
    }
    this.authService.approveUser(this.userId).subscribe(data =>{
      this.notificationService.success('Success', 'Parent consent received. You can close this page now. Email support@peer-squared.com with any questions. Thank you.', {
        timeOut: 12000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
    })
  }
}
