import { Component, OnInit, OnDestroy, ChangeDetectorRef,  Input, Inject, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import {Store, select} from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { Subscription } from 'rxjs';
import { map, take} from 'rxjs/operators';
import { SchoolService } from 'src/app/store/services/school.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { logout } from 'src/app/store/actions/auth.actions';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { HttpClient } from '@angular/common/http';
import { ShortSchoolName} from 'src/app/common'
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { UpdateEmailDialog } from '../onboarding/signup-confirmation/signup-confirmation.component';
import { JoinDialog, openJoinDialog } from '../join/join-dialog';
import { HelpDialog } from '../dashboard/dashboard-page/dashboard-page.component';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  mainState: String;
  isAccountInfo: Boolean;
  isAcademicInfo: Boolean;
  isPersonalInfo: Boolean;
  isTutoringInfo: Boolean;
  authSubscription: Subscription;
  userData = null;
  appointmentsList = [];
  upcommingSessions = [];
  pastSessions = [];
  username = '';
  schoolID = '';
  schoolName = '';
  programName = '';
  dashLogo = "";
  supportEmail = "";
  myCourseList = null;  
  appointmentId = null;
  tutorId = null;
  isApproved = false;
  hasPermission = true;
  allTeachers = [];
  loginError = null;
  tutoringClasses = [];
  tutorClassList = [];
  hours_classes = [];
  isRefreshToken = false;
  isMaintenance = false;  
  availabilities_count = 1
  route: ActivatedRouteSnapshot;
  selectorOpen = false
  

  // This section is for the account information div
  type = '';
  grade = '';
  enableSMS = false;
  email = '';
  dateCreated = '';
  birthday = '';
  firstName = '';
  gender = '';
  lastName = '';
  parentEmail = '';
  personalEmail = '';
  phoneNumber = '';
  studentID = '';
  referrer = '';
  picture = "../../../../../assets/img/profile-icon.png";
  closefunction: any;
  programs: any[];
  active: any;

  constructor(
    private store: Store<any>,
    private router: Router, 
    private schoolService: SchoolService,
    private userService: AppointmentsService, 
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService, 
    private notificationService: NotificationsService,
    public dialog: MatDialog,) { }
  
  openJoinDialog = openJoinDialog
  ngOnInit() {
    this.mainState = 'account'
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];        
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => {      
      this.isMaintenance = data.result['maintenance']
      this.dashLogo = data.result['dashLogo']
    })
    
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.goLoginPage();
      return
    }
    
    localStorage.removeItem('destLink')
    this.userService.getAppointments()
      .subscribe(data => {
        this.userData = data.result;
        // this.isMaintenance = this.userData.school.maintenance                        
        this.username = this.userData['firstname'] + ' ' + this.userData['lastname']
        this.schoolID = this.userData['school']._id;
        //This section is for account info div
        this.type = this.userData['type']
        this.active = this.userData['active']
        this.grade = this.userData['grade']
        this.enableSMS = this.userData['enableSMS']
        this.email = this.userData['email']
        this.dateCreated = this.userData['dateCreated'] // date object type may need some more looking into
        this.birthday = this.userData['birthday']
        this.firstName = this.userData['firstname']
        this.gender = this.userData['gender']
        this.lastName = this.userData['lastname']
        this.parentEmail = this.userData['parentEmail']
        this.personalEmail = this.userData['personalEmail']
        this.phoneNumber = this.userData['phoneNumber']
        this.studentID = this.userData['studentId']
        this.referrer = this.userData['referrer']
        if(this.userData['profilePicture'].img != null)
        {
          this.picture = this.userData['profilePicture'].img
          document.getElementById('picture').style.top = this.userData['profilePicture'].yOffset*.5+ "px"
          document.getElementById('picture').style.left = this.userData['profilePicture'].xOffset*.75  + "px"
          document.getElementById('picture').style.transform = "scale(" + this.userData['profilePicture'].zoom + ")"
        }

        let short_schoolName = this.userData['school'].shortName;  
        this.programName = this.userData['school'].name      
        let schoolNames = [];
        this.userData['schools'].forEach(school =>{
          schoolNames.push(school.shortName)
        })

        let isExist = schoolNames.includes(this.schoolName);                             

        if (this.schoolName == this.userData['school'].shortName){          
          this.schoolID = this.userData['school']._id;      
        }else {
          this.userData['schools'].forEach(school =>{
            if (this.schoolName == school.shortName){
              this.schoolID = school._id;                          
            }
          })
        }

        if (short_schoolName != this.schoolName && !isExist){
          this.notificationService.error('Error', 'You do not have access to this school.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
          this.logout();
          this.router.navigate(['/school', this.schoolName, 'join']);   
        }
    }, error => {
      if (error.error == 'Unauthorized'){
        this.goLoginPage();
      }
    })        
  }
  openSelector(){
    if(this.selectorOpen == false){
      this.userService.getAppointments().subscribe(data => {
          this.userData = data.result;
        })
      this.programs = []
      this.programs.push(this.userData.school)
      this.programs = this.programs.concat(this.userData.schools)
      this.selectorOpen = true
      this.closefunction = this.closeSelector.bind(this)
      setTimeout(()=>
      window.addEventListener('click', this.closefunction), 10)
    }
  }
  closeSelector(e:MouseEvent) {
    if(document.getElementById('program-selector')!=null){
    if(!document.getElementById('program-selector').contains(e.target as Node)) {
        console.log(this)
        this.selectorOpen = false
        console.log(this.selectorOpen)
        window.removeEventListener('click', this.closefunction)
    } }
  }
  switchSchools(school)
  {
    this.router.navigate(['school', school,'profile']).then(()=>
    window.location.reload())
  }
  
  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }
  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }
  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  
  goToOnboarding(){
    this.router.navigate(['/school', this.schoolName, 'tutor-onboarding', 'classes'])
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['/school', this.schoolName, 'login']);  
    localStorage.setItem('destLink', 'search')
  }
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }
  changeMainTab(buttonState: String) {
    this.mainState = buttonState;
    if (buttonState == "account") {
      this.isAccountInfo = true;
      this.isAcademicInfo = false;
      this.isPersonalInfo= false;
      this.isTutoringInfo = false;
    } else if (buttonState == "academic") {
      this.isAccountInfo = false;
      this.isAcademicInfo = true;
      this.isPersonalInfo= false;
      this.isTutoringInfo = false;
    } else if (buttonState == "personal"){
      this.isAccountInfo = false;
      this.isAcademicInfo = false;
      this.isPersonalInfo= true;
      this.isTutoringInfo = false;
    } else if (buttonState == "tutoring"){
      this.isAccountInfo = false;
      this.isAcademicInfo = false;
      this.isPersonalInfo= false;
      this.isTutoringInfo = true;
    }
  }

  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
  

}
