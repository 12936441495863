import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SchoolService } from 'src/app/store/services/school.service';
import { FormControl, FormsModule, NgForm, NgModel } from '@angular/forms';
import { join, login, logout } from 'src/app/store/actions/auth.actions';
import { AuthService } from 'src/app/store/services/auth.service';
import { formatCurrency } from '@angular/common';
import { SELECT_PANEL_PADDING_X } from '@angular/material';
import {Location} from '@angular/common'
import { NotificationsService } from 'angular2-notifications';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm',{static:false}) public loginForm: NgForm;
  school_name: any;
  emailDoesNotExist: boolean;
  incorrectPassword: boolean;
  programCtrl = new FormControl();
  schoolIndex: any;
  studentDomains: any;
  emailSent: boolean = false;
  intraSchool: boolean;
  

  constructor(
    private schoolService: SchoolService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private store: Store<any>,
    private authService: AuthService,
    private location: Location,
    private notificationService: NotificationsService
  ) {
    
    
  }
  login: boolean
  error: boolean
  buttonText: String
  schools: any;
  schoolsSubscription: Subscription;
  schools$: Observable<any>;
  auth$: Observable<any>;
  authSubscription: Subscription;
  school_code: string = null;
  school_shortname: string = null;
  school_id: string = null;
  showPassword: boolean;
  duplicate: boolean;
  tcChecked: boolean = false;
  remember: boolean;
  logo = "../../../../assets/img/ps_login_logo.png";
  coverImg = "../../../../assets/img/blue-blob.png";
  schoolSelected = false;
  badDomain = false;
  ngOnInit() {
    localStorage.removeItem("emailSent")
    
    let isAuthenticated = this.authService.isAuthenticated()    
    if (isAuthenticated){
          this.authService.getUserById(localStorage.getItem('userId')).subscribe(user => {
          let onboardingCompletion = user['onboardingCompletion']
          console.log(onboardingCompletion)
            if(user['confirmed'])
            {
              this.schoolService.getSchoolbyID(user['school']).subscribe(school => {
                this.router.navigate(['school/' + school['short'] + '/dashboard'])
              })
              
            }
            else if(onboardingCompletion['type-select'] == false)
            {
              this.router.navigate(['onboarding/type-select'])
            }
            else if(onboardingCompletion['school-info'] == false)
            {
              this.router.navigate(['onboarding/school-info'])
            }
            else if(onboardingCompletion['personal-info'] == false)
            {
              this.router.navigate(['onboarding/personal-info'])
            }
            else if(user['type'] != 'Tutor' && onboardingCompletion['classes'] == false)
            {
              this.router.navigate(['onboarding/classes'])
            }
            else if(onboardingCompletion['about-me'] == false)
            {
              this.router.navigate(['onboarding/about-me'])
            }
            else if(onboardingCompletion['add-picture'] == false)
            {
              this.router.navigate(['onboarding/add-picture'])
            }
            else if(user['type'] != 'Tutor' && user['helpNeeded'].length != 0 && onboardingCompletion['tutor-recommendation'] == false)
            {
              this.router.navigate(['onboarding/tutor-recommendation'])
            }
            else if(user['confirmed'] == false)
            {
              this.router.navigate(['onboarding/signup-confirmation'])
            }
            else
            {
              this.schoolService.getSchoolbyID(user['school']).subscribe(school => {
                this.router.navigate(['school/' + school['short'] + '/dashboard'])
              })
              
            }
        })
      
    }
    else{
      localStorage.removeItem("savedClassIndex")
      this.activatedRoute.queryParams.subscribe(params => {             
        this.school_shortname = params.schoolId
        this.school_shortname = this.activatedRoute.snapshot.params['schoolId']
      this.schoolService.getSchools().subscribe(schools => {
        this.schools = schools
        if(this.school_shortname != undefined)
        {
          this.schoolIndex = this.schools.findIndex(school => {return school.short == this.school_shortname})
          if (this.schoolIndex != -1)
          {
            this.onSchoolChange(this.schoolIndex)
          }
        }
        else if(localStorage.getItem('schoolShortName') != undefined)
        {
          this.school_shortname = localStorage.getItem('schoolShortName')
          this.schoolIndex = this.schools.findIndex(school => {return school.short == this.school_shortname})
          if (this.schoolIndex != -1)
          {
            this.onSchoolChange(this.schoolIndex)
          }
        }
        else
        {
          this.schoolIndex = -1
        }
         });
      })
      this.login = true
      this.error = false
      this.showPassword = false
      this.duplicate = false
    }
  }

  onSchoolChange(schoolIdx){    
    console.log("school detail",this.schools[schoolIdx])
    this.badDomain = false;
    this.logo = this.schools[schoolIdx].loginLogo;
    this.coverImg = this.schools[schoolIdx].coverImg;
    this.school_id = this.schools[schoolIdx].id;
    this.school_code = this.schools[schoolIdx].code;
    this.school_shortname = this.schools[schoolIdx].short;
    this.school_name = this.schools[schoolIdx].name;
    this.studentDomains = this.schools[schoolIdx].studentDomains
    this.intraSchool = this.schools[schoolIdx].programType == "Intra-School"
    this.schoolSelected = true;
    localStorage.setItem('schoolShortName', this.school_shortname)
    this.location.go('/school/' + this.school_shortname)
    
  }
  checkEmail(email)
  {
    console.log(this.studentDomains)
    if(this.studentDomains == undefined || this.studentDomains.length == 0)
    {
      this.badDomain = false
    }
    else if(this.studentDomains.findIndex(domain => domain.domain == email.substring(email.lastIndexOf('@')+1)) == -1)
    {
      this.badDomain = true
    }
    else
    {
      this.badDomain = false
    }

  }

  showPasswordFunc()
  {
    this.showPassword = !this.showPassword
  }

  onLoginClick()
  {
    this.login = true
    this.error = false
    this.duplicate = false
  }
  onSignupClick()
  {
    this.login = false
    this.duplicate = false
    setTimeout(() => {this.programCtrl.setValue(this.schoolIndex)}, 10);
  }
  resetPassword()
  {
    if (this.loginForm.value.email == undefined || this.loginForm.value.email == "")
    {
      this.notificationService.error('Error', 'Please enter your email in the email field.', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      })
    }
    else{
      this.authService.sendPasswordResetEmail(this.loginForm.value.email).subscribe(res => {
        this.emailSent = true
        this.notificationService.alert('Reset Password', 'Email Sent', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      })},
      err => {
        this.notificationService.error('Error', 'There is no account under this email address.', {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        })
      })
      
    
    }
  }
  onSubmit(form: NgForm)
  {
    this.error = false
    

      this.duplicate = false
      if(this.login)
      {
        this.authService.loginServer(form.value.email.toLowerCase(), form.value.password, this.remember).subscribe( result => {
          
          localStorage.setItem('token', result['token']);
          localStorage.setItem('userId', result['userId']);

          window.analytics.identify( form.value.email.toLowerCase(),{
            email: form.value.email.toLowerCase()
          });
          
          this.authService.getUserById(result['userId']).subscribe(user => {
            console.log(localStorage.getItem('token'))
            console.log(localStorage.getItem('userId'))
            let onboardingCompletion = user['onboardingCompletion']
            
            if(onboardingCompletion['type-select'] == false)
            {
              this.router.navigate(['onboarding/type-select'])
            }
            else if(onboardingCompletion['school-info'] == false)
            {
              this.router.navigate(['onboarding/school-info'])
            }
            else if(onboardingCompletion['personal-info'] == false)
            {
              this.router.navigate(['onboarding/personal-info'])
            }
            else if(user['type'] != 'Tutor' && onboardingCompletion['classes'] == false)
            {
              this.router.navigate(['onboarding/classes'])
            }
            else if(onboardingCompletion['about-me'] == false)
            {
              this.router.navigate(['onboarding/about-me'])
            }
            else if(onboardingCompletion['add-picture'] == false)
            {
              this.router.navigate(['onboarding/add-picture'])
            }
            else if(user['type'] != 'Tutor' && user['helpNeeded'].length != 0 && onboardingCompletion['tutor-recommendation'] == false)
            {
              this.router.navigate(['onboarding/tutor-recommendation'])
            }
            else if(user['confirmed'] == false)
            {
              this.router.navigate(['onboarding/signup-confirmation'])
            }
            else
            {
              this.schoolService.getSchoolbyID(user['school']).subscribe(school => {

               
                this.router.navigate(['school/' + school['short'] + '/dashboard'])
              })
              
            }
          })
          
          
        }, error =>{
          if(error.error == "Email does not exist, please create new account.")
          {
            this.emailDoesNotExist = true
          }
          else if(error.error == "Password is incorrect")
          {
            this.incorrectPassword = true
          }
        })
        
        
      }
      else
      {
        this.checkEmail(form.value.email)
        
        if(!this.badDomain && form.valid)
        {   
        this.authService.registerServer(this.school_name, form.value.email.toLowerCase(), form.value.email.toLowerCase, form.value.password).subscribe( result => {
          // this.registered = true;
          // this.loginEmail = form.value.email;
          // this.communicationEmail = form.value.personalEmail;
          console.log(result)
          localStorage.setItem('token', result['token']);
          localStorage.setItem('userId', result['userId']);

          window.analytics.identify( form.value.email.toLowerCase(),{
            email: form.value.email.toLowerCase(),
            program: this.school_name
          });

          this.router.navigate(['onboarding/type-select'])
        }, error => {     
          console.log(error)
          if (error.error == "Email already exists")
          {
            this.duplicate = true 
          }
          // this.notificationService.error('Error', error.error, {
          //   timeOut: 3000,
          //   showProgressBar: true,
          //   pauseOnHover: true,
          //   clickToClose: true
          // });
        })
      }
      else{
        this.error = true;
      }
      }
    
    
    
  }

}
