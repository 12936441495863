import { Component } from '@angular/core';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'peersquared';
  public options = {
    position: ["bottom", "right"],
    lastOnBottom: true,
    timeOut: 2000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    maxLength: 1000
  };  
}



