import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { Router, Route, CanActivate, ActivatedRouteSnapshot, CanLoad } from '@angular/router';
import { AuthService } from '../store/services/auth.service';
import { Store, select} from '@ngrx/store';
import { tap , take, map, filter, first} from 'rxjs/operators';
import { SchoolService } from '../store/services/school.service';
import {loadSchools, loadSucceed} from '../store/actions/school.actions';

@Injectable()
export class DataGuardService implements CanActivate{
    
    constructor(
        private schoolService: SchoolService, 
        private router: Router, 
        private store: Store<any>
    ){}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean{
        
        
        let schoolsObservable = this.store.select('schools').pipe(
            tap(schools=>{
                this.store.dispatch(loadSchools({}));
                return;
            }),
            map(schools => true)
        );
        return schoolsObservable;
    }
}