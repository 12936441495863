import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { SchoolService } from 'src/app/store/services/school.service';
import { SchoolSessionLimitDialog } from '../../class/class-page/class-page.component';
import { NotificationsService } from 'angular2-notifications';


@Component({
  selector: 'app-type-select',
  templateUrl: './type-select.component.html',
  styleUrls: ['./type-select.component.css', '../onboarding.css']
})
export class TypeSelectComponent implements OnInit {
  
  constructor(
    private store: Store<any>,
    private authService:AuthService,
    private router: Router,
    private schoolService:SchoolService,
    private notificationService:NotificationsService) {}
    typeCtrl = new FormControl()
  ngOnInit() {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);   
      this.logout()     
      return
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
      if (res['confirmed']){
        this.router.navigate([''])
      }
      this.typeCtrl.setValue(res['type'])})
    // this.tokenRefresh()
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }
  tokenRefresh(){
    this.authService.tokenRefresh().subscribe(data => {        
      localStorage.setItem('token', data['token']);
      // this.getAppointments();

      // setTimeout(() => {        
      //   this.showTokenRefreshDialog();
      // }, 3000000); // Activate after 50 minutes.
      
    }, error => {
      if (error.status == 401){
        this.logout();
        this.router.navigate(['']);  
      }
    })        
  }

  onSubmit(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    this.authService.getUserById(localStorage.getItem('userId')).subscribe(user => {
      this.schoolService.addUser(localStorage.getItem('userId'), this.typeCtrl.value, user['school']).subscribe(res =>{})
    })
    
    this.authService.update(localStorage.getItem('userId'), { type: this.typeCtrl.value,
      onboardingCompletion: {
        "type-select": true
      }}).subscribe(res => {
        window.analytics.track("Account Type Submitted",{
          type: this.typeCtrl.value
        });
        this.router.navigate(['onboarding/school-info'])
      })
  }

}
