import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { Router, Route, CanActivate, ActivatedRouteSnapshot, CanLoad } from '@angular/router';
import { AuthService } from '../store/services/auth.service';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import {decipherToken} from '../store/actions/auth.actions';

@Injectable()
export class AuthGuardService implements CanActivate{
    constructor(private auth: AuthService, private router: Router, private store: Store<any>){}


    populateStore(token, parsedToken): Observable<any>{
        return this.store
            .select('auth')
            .pipe(
                tap((data)=>{
                    if(data['grade'] != 0){
                        this.store.dispatch(decipherToken({token, parsedToken}));
                    }
                })
            )
    }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean{
        console.log("Activating");
        console.log(route.params);
        const authRoute = route.data.authRoute;
        console.log(authRoute);
        if(!this.auth.isAuthenticated()){
            this.auth.removeToken();
            if(authRoute){
                return true;
            }else{
                this.router.navigate(['']);
                return false;
            }
        }
        const token = this.auth.retrieveToken();
        const parsedToken = this.auth.parseToken(token);
        this.store.dispatch(decipherToken({token, parsedToken}));
        if(authRoute){
            console.log("asdfa")
            this.router.navigate(['dashboard']);
            return false;
        }
        return true;
    }
    
}