import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { map, startWith } from 'rxjs/operators';
import { SchoolService } from 'src/app/store/services/school.service';
import { Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css', '../onboarding.css']
})
export class AboutMeComponent implements OnInit {
  myClubs = [];
  clubCtrl: FormControl = new FormControl();
  clubs
  type:string;
  filteredClubs: Observable<string[]>;
  tuteeImg = "../../../../../assets/img/onboarding-progress-about-me-tutee.svg"
  tutorImg = "../../../../../assets/img/onboarding-progress-about-me-tutor.svg"

  constructor(
    private notificationService:NotificationsService,
    private store: Store<any>,
    private router: Router,
    private authService: AuthService,
    private schoolService: SchoolService) {
        
     }

  ngOnInit() {
    
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);   
      this.logout()     
      return
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(user => {
      if (user['confirmed']){
        this.router.navigate([''])
      }
      this.type = user['type']
      this.schoolService.getSchoolbyID(user['school']).subscribe(school => {
        this.clubs = school['clubsAndExtracurriculars']
        console.log(this.clubs)
        this.filteredClubs = this.clubCtrl.valueChanges.pipe(
          startWith(null),
          map((club: string | null) => club ? this._filter(club) : this.clubs.slice()));
      })})
      
    
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout())          
  }

  add(event: MatChipInputEvent)
  {
    const value = (event.value || '').trim();

    // Add a club
    if (value) {
      this.myClubs.push(value);
    }

    // Clear the input value
    event.input.value = "";
    // this.clubCtrl.setValue(null);
  }
  remove(rmClass: string)
  {
    const index = this.myClubs.indexOf(rmClass);
    if (index >= 0) {
      this.myClubs.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.myClubs.push(event.option.viewValue);
    this.clubCtrl.setValue(null);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.clubs.filter(club => club.toLowerCase().includes(filterValue));
  }
  skip(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    this.authService.update(localStorage.getItem('userId'), 
    {
      onboardingCompletion: {
          "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": true,
          "add-picture": false,
          "tutor-recommendation": false
      }

      }).subscribe(res =>
      {
        window.analytics.track("About Me Skipped",{
          // id: localStorage.localStorage.getItem('userId')
        })
        this.router.navigate(['onboarding/add-picture'])
      })
  }
  onSubmit(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    var personality = {}
    console.log(form.value.personality)
    if(form.value.personality == "introvert"){
      personality = {introverted: true, extroverted: false, extrovertedWithFriends: false}
    }
    else if(form.value.personality == "extrovert"){
      personality = {introverted: false, extroverted: true, extrovertedWithFriends: false}
    }
    else if(form.value.personality == "extrovertwFriends"){
      personality = {introverted: false, extroverted: false, extrovertedWithFriends: true}
    }

    var learningStyle = {}
    if(form.value.learningStyle == "visual")
    {
      learningStyle = {visual: true, auditory: false, kinesthetic: false, readingWriting: false}
    }
    else if(form.value.learningStyle == "auditory")
    {
      learningStyle = {visual: false, auditory: true, kinesthetic: false, readingWriting: false}
    }
    else if(form.value.learningStyle == "kinesthetic")
    {
      learningStyle = {visual: false, auditory: false, kinesthetic: true, readingWriting: false}
    }
    else if(form.value.learningStyle == "readingWriting")
    {
      learningStyle = {visual: false, auditory: false, kinesthetic: false, readingWriting: true}
    }
    
    this.authService.update(localStorage.getItem('userId'), 
    {
      personalityType: personality,
      learningStyle: learningStyle,
      clubsAndExtracurriculars: this.myClubs,
      onboardingCompletion: {
        "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": true,
          "add-picture": false,
          "tutor-recommendation": false
      }

      }).subscribe(res =>
      {
        window.analytics.track("About Me Submitted",{
          personalityType: form.value.personality,
          learningStyle: form.value.learningStyle,
          clubsAndExtracurriculars: this.myClubs
        })
        this.router.navigate(['onboarding/add-picture'])
      })
  }
}
