import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/store/services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css', '../onboarding/onboarding.css']
})
export class PasswordResetComponent implements OnInit {
  showPassword = false
  userId
  resetCode
  password
  constructor(private authService: AuthService,
    private activatedRoute:ActivatedRoute,
    private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {             
      this.userId = params['userId'];            
      this.resetCode = params['resetCode']})
   }

  ngOnInit() {
  }
  showPasswordFunc()
  {
    this.showPassword = !this.showPassword
  }

  onSubmit(form: NgForm){
    this.authService.resetPassword(this.userId, this.resetCode, form.value.password).subscribe(res =>
      {
        this.router.navigate([''])
      }
      )

  }

}
