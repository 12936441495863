import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { of, EMPTY } from 'rxjs'
import { Router } from '@angular/router';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators'
import * as SchoolActions from '../actions/school.actions'
import {SchoolService} from '../services/school.service'

@Injectable()
export class SchoolEffects{
    loadSchools$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SchoolActions.loadSchools),
            tap(actions=> {                
                return actions;
            }),
            exhaustMap(actions => 
                this.schoolService.getSchools().pipe(
                    map(output => {                        
                        return SchoolActions.loadSucceed({schools: output});
                    })
                )
            )
        )
        }
    );
    loadSucceed$ =  createEffect(()=>
        this.actions$.pipe(
            ofType(SchoolActions.loadSucceed)            
        ),
        {dispatch: false}
    )
    constructor(
        private actions$: Actions,
        private schoolService: SchoolService, 
        private router: Router
    ){}
}