import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ClassDetailService {    
    constructor(private http: HttpClient) {}
    getClassByID(classID) {
        let token = localStorage.getItem('token')
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };         
        return this.http.get(`${environment.backend}/api/courses/course/${classID}`, httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
}
