import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class SearchService {    
    httpConfig = {}
    constructor(private http: HttpClient) {}
    setupHttpConfig(){
        let token = localStorage.getItem('token')
        this.httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };              
    }
    getAllClasses(schoolID) {
        this.setupHttpConfig()       
        return this.http.get(`${environment.backend}/api/courses/${schoolID}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    updateClass(courseId, schoolId){
        this.setupHttpConfig()         
        return this.http.put(`${environment.backend}/api/courses/tutor/request/${courseId}/${schoolId}`,{school: ''}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }

    updateTutoredHours(duration, courseId){
        this.setupHttpConfig()         
        return this.http.put(`${environment.backend}/api/courses/hours/${duration}/${courseId}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    
    deleteClassByID(classID) {
        this.setupHttpConfig()         
        return this.http.get(`${environment.backend}/api/courses/delete/${classID}`, this.httpConfig).pipe(
            map(res=> {
                console.log(res)
                return {
                    result: res
                }
            }),
            take(1)
        );
    }
}
