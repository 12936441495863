import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AppointmentsService {    
    httpConfig = {};
    storage = window.localStorage;
    constructor(private http: HttpClient) {}
    configHttpHeader() {
        const token = this.storage.getItem('token');
        this.httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };
    }
    getAppointments() {
        this.configHttpHeader();
        return this.http.get(`${environment.backend}/api/users/current/deep`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    
    registerTutor(startTimes: any, endTimes: any, useGlobal: boolean, dayOfWeek: string, preference: string, location: any, duration: number, courseId: string, schoolId: string, dayLimit: number) {    
        this.configHttpHeader();             
        return this.http.put(`${environment.backend}/api/users/availability`, {startTimes, endTimes, useGlobal, dayOfWeek, preference, location, duration, courseId, schoolId, dayLimit}, this.httpConfig ).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
}
