import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ScheduleService {    
    httpConfig = {}
    constructor(private http: HttpClient) {}
    setupHttpConfig(){
        let token = localStorage.getItem('token')
        this.httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };              
    }
    getSessions(schoolId, userId, days) {           
        this.setupHttpConfig()
        return this.http.get(`${environment.backend}/api/appointments/sessions/${schoolId}/${userId}/${days}`,this.httpConfig ).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    getExistingSessions(availabilityId) {           
        this.setupHttpConfig()
        return this.http.get(`${environment.backend}/api/appointments/existing-sessions/${availabilityId}`,this.httpConfig ).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    requestBookSession(courseId, schoolId, tutorId, id, location, teacher: string, help: string, comment: string, fileList: any) {    
        this.setupHttpConfig()
        return this.http.post(`${environment.backend}/api/appointments/schedule/${courseId}/${schoolId}/${tutorId}`, {id, location, teacher, help, comment, fileList},this.httpConfig ).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    approveAppointment(userId, appointmentId){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/approve/${userId}/${appointmentId}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    rejectAppointment(userId, appointmentId){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/reject/${userId}/${appointmentId}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    saveVideoCallLink(appointmentId, videoCallLink){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/${appointmentId}`, {videoCallLink}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    cancelAppointment(userId, appointmentId){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/cancel/${userId}/${appointmentId}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    getAppointmentById(appointmentId) {
        this.setupHttpConfig()
        return this.http.get(`${environment.backend}/api/appointments/${appointmentId}`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    saveNotes(appointmentId, notes, fileList, noShowStatus){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/notes/${appointmentId}`, {notes, fileList, noShowStatus}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    updateSessionStatus(appointmentId){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/tutored/${appointmentId}`, {}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    getTutoredHours(schoolId, tutorId, courseId){
        this.setupHttpConfig();
        return  this.http.post(`${environment.backend}/api/appointments/sessions/hours`, {schoolId, tutorId, courseId}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    saveTutorFeedback(appointmentId, tutorFeedback){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/tutor/feedback/${appointmentId}`, {tutorFeedback}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    saveStudentFeedback(appointmentId, studentFeedback, tutored){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/student/feedback/${appointmentId}`, {studentFeedback, tutored}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }

    getNextAppointments(schoolId, courseId, tutorId){
        this.setupHttpConfig();
        return this.http.get(`${environment.backend}/api/appointments/${schoolId}/${courseId}/${tutorId}/`, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }

    updateComment(appointmentId, comment){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/student/updateComment/${appointmentId}`, {comment}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }

    updateFiles(appointmentId, fileList){
        this.setupHttpConfig();
        return this.http.put(`${environment.backend}/api/appointments/student/updateFiles/${appointmentId}`, {fileList}, this.httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
}
