import {Action, createReducer, on} from '@ngrx/store'
import * as AuthActions from '../actions/auth.actions'
export interface State{
    firstname: string;
    lastname: string;
    email: string;
    gender: string;
    schoolID: string;
    grade: number;
    token: string;
};
export const initialState: State = {
    firstname: '',
    lastname: '',
    email: '',
    gender: '',
    schoolID: '',
    grade: 0,
    token: ''
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.loginSucceed, (state, {token, parsedToken})=>{
        return {
            ...state,
            firstname: parsedToken.firstname,
            lastname: parsedToken.lastname,
            email: parsedToken.email,
            gender: parsedToken.gender,
            schoolID: parsedToken.schoolID,
            grade: parsedToken.grade,
            token
        }
    }),
    on(AuthActions.registerSucceed, (state, {token, parsedToken})=>{        
        return {
            ...state,
            email: parsedToken.email,
            token
        }
    }), 
    on(AuthActions.profileCreationSucceed, (state, {token, parsedToken})=>{        
        return {
            ...state,
            firstname: parsedToken.firstname,
            lastname: parsedToken.lastname,
            gender: parsedToken.gender,
            grade: parsedToken.grade,
            schoolID: parsedToken.schoolID,
            token
        }
    }),
    on(AuthActions.decipherToken, (state, {token, parsedToken})=>{
        return {
            ...state,
            firstname: parsedToken.firstname,
            lastname: parsedToken.lastname,
            gender: parsedToken.gender,
            grade: parsedToken.grade,
            schoolID: parsedToken.schoolID,
            token
        }
    }),
    on(AuthActions.logout, (state)=> {
        console.log('auth reducer logout')
        return {
            ...state, 
            firstname: '',
            lastname: '',
            gender: '',
            grade: 0,
            schoolID: '',
            token: ''
        }
    }),

)