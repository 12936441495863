import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { SearchService } from 'src/app/store/services/search.service';
import { ClassDetailService } from 'src/app/store/services/classDetail.service';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { ScheduleService } from 'src/app/store/services/schedule.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { MatDialog } from '@angular/material';
import { TutorsSelectDialog } from '../../class/class-page/class-page.component';
import { TeachersService } from 'src/app/store/services/teacher.service';

@Component({
  selector: 'app-tutor-recommendation',
  templateUrl: './tutor-recommendation.component.html',
  styleUrls: ['./tutor-recommendation.component.css', '../onboarding.css']
})
export class TutorRecommendationComponent implements OnInit {
  classes = [];
  selectedClassName: any;
  class;
  displayedTutors = [];
  appointmentLists = [];
  gradeNames = ['Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', 
  '5th Grade', '6th Grade', '7th Grade', '8th Grade', '9th Grade',
   '10th Grade', '11th Grade', '12th Grade']
  availabilityParameters: any;
  classIndex: any;
  tutorImgs = []
  innerWidth = window.innerWidth;
  classInfo;
  requireDetails: any;
  teachers = [];
  nextSessionList: { result: Object; };
  bookAnother = false;
  classId: any;
  schoolId: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationsService,
    private store: Store<any>,
    private searchService: SearchService,
    private classDetailService: ClassDetailService,
    private appointmentsService: AppointmentsService,
    private scheduleService: ScheduleService,
    private schoolService: SchoolService,
    public dialog: MatDialog,
    private teacherService: TeachersService) { 
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;    
  }
  
  ngOnInit() {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);
      this.logout()
      return
    }
    
    this.classIndex = localStorage.getItem("savedClassIndex")
    if(this.classIndex == undefined || this.classIndex == null)
    {
      this.classIndex = 0
    }
    else
    {
      this.bookAnother = true
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(user =>{
      this.classes = user['helpNeeded']
      this.schoolId = user['school']
      if(this.classIndex >= this.classes.length)
      {
        this.router.navigate(['onboarding', 'signup-confirmation'])
      }
      else{
        this.schoolService.getSchoolbyID(user['school']).subscribe(school=>{
          this.requireDetails = school['requireDetails']
          this.availabilityParameters = school['availabilityParameters']
          this.selectedClassName = this.classes[this.classIndex]
          this.searchService.getAllClasses(user['school']).subscribe(schoolClassList => {
            var classId = (schoolClassList.result as Array<Object>).find(item => item['name'] == this.selectedClassName)['_id']
            this.classId = classId
            // This needs to be updated when new schools are added (there also needs to be a new function added in services)
            if(school['name'] == "Hamilton HS Peer Tutoring")
            {
              this.teacherService.getHamiltonTeachersJSON().subscribe(teachers => this.teachers = teachers[this.classes[this.classIndex]])
            }
            else if(school['name'] == "Tarwater Summer Mandarin Tutoring")
            {
              this.teacherService.getMandarinTeachersJSON().subscribe(teachers => this.teachers = teachers[this.classes[this.classIndex]])
            }
            else if(school['name'] == "Tempe Union Peer Tutoring")
            {
              this.teacherService.getTempeUnionTeachersJSON().subscribe(teachers => this.teachers = teachers[this.classes[this.classIndex]])
            }
            if (this.teachers == undefined) {this.teachers = []}
            this.classDetailService.getClassByID(classId).subscribe(selectedClass =>{
              this.classInfo = selectedClass
              this.displayTutors(selectedClass, user, classId)
              
            })
          })
        })
      }
    })
  }

  loadNewClass()
  {
    this.displayedTutors = []
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);
      this.logout()
      return
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(user =>{
      this.classes = user['helpNeeded']
      this.schoolService.getSchoolbyID(user['school']).subscribe(school=>{
        this.availabilityParameters = school['availabilityParameters']
        this.selectedClassName = this.classes[this.classIndex]
        this.searchService.getAllClasses(user['school']).subscribe(schoolClassList => {
          var classId = (schoolClassList.result as Array<Object>).find(item => item['name'] == this.selectedClassName)['_id']
          this.classId = classId
          this.classDetailService.getClassByID(classId).subscribe(selectedClass =>{
            this.classInfo = selectedClass
            this.displayTutors(selectedClass, user, classId)
            
          })
        })
      })
    })
  }
  
  displayTutors(selectedClass, user, classId)
  {
    this.filterTutors(selectedClass.result['content'].tutors, user, classId).then(res => {
      this.displayedTutors = res
      this.displayedTutors.forEach(tutor => {
        this.scheduleService.getNextAppointments(user['school'], classId, tutor['_id']).subscribe(schedule=>{
          var appointments = []
          var filteredAppointments =[]
          schedule.result['data'].forEach(day => {
            let dayName = Object.keys(this.availabilityParameters).find(item => item.slice(0, 3) == day.date.split(" ")[0])
            let allowedTimes = this.getAllowedTimes(dayName, user)
            day.times.forEach(time =>{
              if(allowedTimes.includes(time.split(" - ")[0])){
                filteredAppointments.push({date: day.date, time: time, tutors:day.tutors[time]})
              }
              appointments.push({date: day.date, time: time, tutors:day.tutors[time]})
            })
          })
          if (filteredAppointments.length == 0)
          {
            this.appointmentLists.push(appointments)
          }
          else{
            this.appointmentLists.push(filteredAppointments)
          }
          document.getElementById(tutor._id).style.top = tutor['profilePicture'].yOffset*(50/200)+ "px"
          document.getElementById(tutor._id).style.left = tutor['profilePicture'].xOffset*(80/200)  + "px"
          document.getElementById(tutor._id).style.transform = "scale(" + tutor['profilePicture'].zoom + ")"
        })
          
      })
    })
  }

  async filterTutors(tutors: Array<any>, user, classId){
    var filteredTutors = []
    tutors.forEach(tutor => {
      // if more than 2 years apart
      if(!(Math.abs(tutor.grade - user.grade) > 2)){
        this.scheduleService.getNextAppointments(user['school'], classId, tutor['_id']).subscribe(schedule=>{
          var rejectTutor = schedule.result['data'].every(day => {
            let dayName = Object.keys(this.availabilityParameters).find(item => item.slice(0, 3) == day.date.split(" ")[0])
            let allowedTimes = this.getAllowedTimes(dayName, user)
            if(day.times.findIndex(item => allowedTimes.includes(item.split(" - ")[0])) != -1)
            {
              return false
              
            }
            return true
          })
          if(!rejectTutor)
          {
            filteredTutors.push(tutor)
          }
        })
        
      }
    })
    var returnArray = filteredTutors.slice(0,3)
    await new Promise((resolve)=> setTimeout(()=>{
      
      if(filteredTutors.length == 0){
        returnArray = tutors.slice(0, 3)
      }
      else
      {
        returnArray = filteredTutors.slice(0, 3)
      }
      resolve(0)
    }, 1000))
    return returnArray
  }

  getAllowedTimes(dayName, user)
  {
    var allowedTimes = []
    if(dayName == "Saturday" || dayName == "Sunday")
    {
      if(user['preferredTimes'].weekendMorning){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].weekendMorning.startTimes)}
      if(user['preferredTimes'].weekendAfternoon){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].weekendAfternoon.startTimes)}
      if(user['preferredTimes'].weekendEvening){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].weekendEvening.startTimes)}
    }
    else if(dayName != undefined){
      if(user['preferredTimes'].beforeSchool){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].beforeSchool.startTimes)}
      if(user['preferredTimes'].duringSchool){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].duringSchool.startTimes)}
      if(user['preferredTimes'].afterSchool){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].afterSchool.startTimes)}
      if(user['preferredTimes'].evenings){allowedTimes = allowedTimes.concat(this.availabilityParameters[dayName].evenings.startTimes)}
    }
    return allowedTimes
  }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }

  bookAppointment(tutor, appointment){
    let dialog_height = '70%';
          if (this.innerWidth < 450){
            dialog_height = '200%'
          }                 
          let helps = ['Homework help', 'Test prep', 'Class content', 'Get organized', 'Other']
          const dialogRef = this.dialog.open(TutorsSelectDialog, {      
            data: {teacher: this.teachers[0], teachers: this.teachers, tutor: appointment.tutors[0], tutors: appointment.tutors, help: 'Homework help', helps: helps, comment: '', time: appointment['time'], classname: this.selectedClassName, location: appointment.tutors[0].availability.location[0], date: appointment['date'], requireDetails: this.requireDetails},
            height: dialog_height
          });
          dialogRef.afterClosed().subscribe(data => {
            if(data != undefined){
            this.scheduleService.requestBookSession(this.classId, this.schoolId, data.event.tutor.tutor._id, data.event.tutor._id, data.event.location, data.event.teacher, data.event.help, data.event.comment, data.event.fileList).subscribe(res =>{
            if(this.bookAnother == false){
              this.classIndex++
              localStorage.setItem("savedClassIndex", this.classIndex)
              this.router.navigate(['onboarding/signup-confirmation'])
              
            }
            else{
              this.notificationService.success("Success!", "Session Booked",{
                timeOut: 3000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              })
              this.skip()

            }
          })}
          })
          
  }

  skip(){
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    this.classIndex++
    if (this.classIndex < this.classes.length){
      this.loadNewClass();
    }
    else{
    this.authService.update(localStorage.getItem('userId'), 
    {
      onboardingCompletion: {
        "type-select": true,
        "school-info": true,
        "personal-info": true,
        "classes": true,
        "about-me": true,
        "add-picture": true,
        "tutor-recommendation": true
      }}).subscribe(res =>
       {
        localStorage.setItem("savedClassIndex", this.classIndex)
        this.router.navigate(['onboarding/signup-confirmation'])})
      }
    
  }

}
