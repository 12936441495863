import {Action, createReducer, on} from '@ngrx/store'
import * as SchoolActions from '../actions/school.actions'
interface School {
    id: string,
    name: string,
    short: string
}
interface Schools extends Array<School>{}
export interface State{
    schools: Schools
}
export const initialState: State = {
    schools:  []
}
export const schoolReducer = createReducer(
    initialState,
    on(SchoolActions.loadSucceed, (state, {schools})=>{
        return {
            ...state,
            schools: schools
        }
    })
)