import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AvailabilityService {    
    constructor(private http: HttpClient) {}
    getAvailabilitiesByID(schoolId) {
        console.log("schoolId", schoolId)
        let token = localStorage.getItem('token')
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };         
        return this.http.get(`${environment.backend}/api/availabilities/${schoolId}`, httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }

    deleteAvailability(availabilityId, availabilities_count){
        let token = localStorage.getItem('token')
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };  
        return this.http.put(`${environment.backend}/api/availabilities/delete/${availabilityId}`,{availabilities_count}, httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    updateAvailability(availabilityId, paused){
        let token = localStorage.getItem('token')
        const httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };  
        return this.http.put(`${environment.backend}/api/availabilities/pause/${availabilityId}`, {paused}, httpConfig).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
}
