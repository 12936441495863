import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  choice: boolean
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class WarningGuard implements CanDeactivate<ComponentCanDeactivate> {
  dialog: MatDialog;
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
  
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      confirm("You're about to exit tutor onboarding. All of your class and availability info will be lost. Are you sure you want to exit?");
  }
}



@Component({
  selector: 'warning',
  templateUrl: 'warning.html',
  styleUrls: ['./warning.css']
})

export class WarningDialog {

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<WarningDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

