import { Component, OnInit, OnDestroy, ChangeDetectorRef,  Input, Inject, NgZone } from '@angular/core';
import {Store, select} from '@ngrx/store';
import { AuthService} from '../../../../store/services/auth.service';
import { Subscription } from 'rxjs';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { AvailabilityService } from 'src/app/store/services/availability.service';
import { ScheduleService } from 'src/app/store/services/schedule.service';
import { TeachersService } from 'src/app/store/services/teacher.service';
import { SearchService } from 'src/app/store/services/search.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { logout } from 'src/app/store/actions/auth.actions';

declare var $: any;

@Component({
  selector: 'app-teacher-dashboard-page',
  templateUrl: './teacher-dashboard-page.component.html',
  styleUrls: ['./teacher-dashboard-page.component.css']
})
export class TeacherDashboardPageComponent implements OnInit {
  authSubscription: Subscription;
  userData = null;
  appointmentsList = [];
  pastSessions = [];
  username = '';
  schoolID = '';
  schoolName = '';
  myCourseList = null;  
  appointmentId = null;
  tutorId = null;
  isApproved = false;
  hasPermission = true;
  allTeachers = [];
  loginError = null;
  tutoringClasses = [];
  tutorClassList = [];
  isRefreshToken = false;

  upcommingSessions = [];
  upComingColumns: string[] = ['date', 'day', 'startTime', 'endTime', 'studentName', 'periodClass', 'sessionStatus', 'detail'];

  myClassList = [];
  myClassColumns: string[] = ['period', 'className', 'students', 'upcomingSession', 'detail'];
  


  route: ActivatedRouteSnapshot;
  constructor(private store: Store<any>, private ref: ChangeDetectorRef, private appointments: AppointmentsService, private availabilityService:  AvailabilityService, private scheduleService:  ScheduleService, private notificationService: NotificationsService,private teacherService: TeachersService, private classService: SearchService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog) { 
    this.activatedRoute.queryParams.subscribe(params => {      
      this.appointmentId = params['appointmentId'];         
      if (this.appointmentId == undefined){
        return;
      }
      this.hasPermission = true;
      this.tutorId = params['tutorId'];
      if (this.tutorId == undefined){        
        return
      }

      let userId = localStorage.getItem('userId');            
      this.route = this.activatedRoute.snapshot;
      let schoolName = this.route.params['schoolID'];
      if (userId == null){   
        localStorage.setItem('appointmentId', this.appointmentId);
        localStorage.setItem('tutorId', this.tutorId);
        this.router.navigate(['/school', schoolName, 'login']);          
        return;
      }
    });
  }
  tokenRefresh(){
    this.authService.tokenRefresh().subscribe(data => {        
      localStorage.setItem('token', data['token']);      

      setTimeout(() => {        
        this.showTokenRefreshDialog();
      }, 3000000); // Activate after 50 minutes.
      
    }, error => {
      if (error.status == 401){
        this.logout();        
      }
    })        
  }
  showTokenRefreshDialog() {    
    this.isRefreshToken = false;
    
    const dialogRef = this.dialog.open(TeacherTokenRefreshDialog, {
      width: '250px'      
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.isRefreshToken = true;
    });

    setTimeout(() => {
      if (!this.isRefreshToken){
        this.dialog.closeAll();
        this.logout();        
      }
    }, 60000); // Activate after 15 seconds

  }  
  ngOnInit() {      
    this.upcommingSessions = [
      {date: '4/17', day: 'Friday', startTime: '3:00', endTime: '3:15', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Confirmed'},
      {date: '4/20', day: 'Monday', startTime: '3:15', endTime: '3:30', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Pending'},
      {date: '4/21', day: 'Friday', startTime: '2:00', endTime: '2:15', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Confirmed'},
      {date: '4/21', day: 'Friday', startTime: '2:00', endTime: '2:15', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Confirmed'},
      {date: '4/22', day: 'Friday', startTime: '2:30', endTime: '2:45', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Confirmed'},
      {date: '4/23', day: 'Friday', startTime: '3:45', endTime: '4:00', studentName: 'Michael Wang', periodClass: 'P1, Algebra 1', sessionStatus: 'Confirmed'},
    ]
    this.myClassList = [
      {period: 'Period 1', className: 'Algebra 1', students: '5 students ', upcomingSession: 'Yes'},
      {period: 'Period 3', className: 'Algebra 1', students: '3 students ', upcomingSession: 'Yes'}
    ]
    let userStatus = localStorage.getItem('userStatus');
    if (userStatus == 'true'){
      this.isApproved = true;
    }

    localStorage.removeItem('appointmentId')
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];

    this.tokenRefresh()
  }
  onPeriod(element){
    console.log("period", element)
  }
  showDetailsUpcomingSession(item){
    console.log('showDetailsUpcommingSession', item)
  }
  showDetailsMyClass(item){
    console.log('showDetailsMyClass', item)
  }


  getHamilonTeachers(){
    this.allTeachers = [];
    this.teacherService.getHamiltonTeachersJSON().subscribe(data => {      
      this.allTeachers = data;   
    })    
  }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    this.store.dispatch(logout());
    this.router.navigate(['/school', this.schoolName, 'login']);          
  }
}

@Component({
  selector: 'teacher-token-refresh-dialog',
  templateUrl: 'teacher-token-refresh-dialog.html',
})
export class TeacherTokenRefreshDialog {

  constructor(
    public dialogRef: MatDialogRef<TeacherTokenRefreshDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    }

  onClose(): void {
    this.dialogRef.close();
  }
  refreshToken(){
    this.dialogRef.close();
  }

}
