import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { Selector, Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { SchoolService } from 'src/app/store/services/school.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-school-info',
  templateUrl: './school-info.component.html',
  styleUrls: ['./school-info.component.css', '../onboarding.css']
})

export class SchoolInfoComponent implements OnInit {
  tutorImg = '../../../../../assets/img/onboarding-progress-school-info-tutor.svg'
  tuteeImg = '../../../../../assets/img/onboarding-progress-school-info-tutee.svg'
  type: string
  schools = []
  schoolCtrl: FormControl = new FormControl();
  filteredSchools;
  classCtrl: any;
  gradeCtrl = new FormControl();
  public schoolForm: NgForm;
  
  constructor(
    private authService: AuthService,
    private store: Store<any>,
    private router: Router,
    private schoolService: SchoolService,
    private notificationService: NotificationsService
    
  ) {
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
      if (res['confirmed']){
        this.router.navigate([''])
      }
      this.type = res['type']
      this.schoolCtrl.setValue(res['schoolName'])
      this.gradeCtrl.setValue(res['grade'])
      console.log(this.schoolCtrl.value)
      console.log(this.gradeCtrl.value)
    })
  }
  grades = []
  gradeNames = ['Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', 
  '5th Grade', '6th Grade', '7th Grade', '8th Grade', '9th Grade',
   '10th Grade', '11th Grade', '12th Grade']
  ngOnInit() {
    
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);
      this.logout()
      return
    }
    this.authService.getUserById(localStorage.getItem('userId')).subscribe(user => {
      
      this.schoolService.getSchoolbyID(user['school']).subscribe(school => {
        this.schools = school['schoolNames']
        console.log(school['grades'])
        this.grades = school['grades']
      })
    })
    
    
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.schools.filter(school => school.toLowerCase().includes(filterValue));
  }


  onSubmit(form: NgForm){
    
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    
    this.authService.update(localStorage.getItem('userId'), 
    {
      schoolName: this.schoolCtrl.value, grade: this.gradeCtrl.value,
      onboardingCompletion: {
        "type-select": true,
        "school-info": true,
        "personal-info": false,
        "classes": false,
        "about-me": false,
        "add-picture": false,
        "book-session": false
      }}).subscribe(res =>
       {
        window.analytics.track("School Info Submitted",{
          schoolName: this.schoolCtrl.value,
          grade: this.gradeCtrl.value
        }); 
        this.router.navigate(['onboarding/personal-info'])
      })
    
  }
  goBack()
  {
    this.router.navigate(['onboarding/type-select'])
  }

}
