import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/store/services/auth.service';
import { SearchService } from 'src/app/store/services/search.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css', '../onboarding.css']
})
export class ClassesComponent implements OnInit {
  myClasses: string[] = [];
  classCtrl = new FormControl();
  classes = [];
  filteredClasses: Observable<string[]>;
  allClasses: any = [];
  schoolId: Observable<Object>;
  type: string;
  
  

  constructor(
    private router: Router, 
    private searchService: SearchService, 
    private authService: AuthService,
    private store: Store<any>,
    private notificationService: NotificationsService,) { 

    

  }

  ngOnInit() {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);
      this.logout()
      return
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
      if (res['confirmed']){
        this.router.navigate([''])
      }
      this.type = res['type']
      this.schoolId = res['school']
      console.log(this.schoolId)
    
      this.searchService.getAllClasses(this.schoolId).subscribe(data => {
        (data.result as Array<Object>).map(entry => 
          this.classes.push(entry['name']))
        console.log('class data', this.classes)
        //this.cloneClasses = Object.assign([], this.classes) 
        this.filteredClasses = this.classCtrl.valueChanges.pipe(
          startWith(null),
          map((aClass: string | null) => aClass ? this._filter(aClass) : this.classes.slice()));  
      })
    })
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }
  add(event: MatChipInputEvent)
  {
    const value = (event.value || '').trim();

    if (value) {
      this.myClasses.push(value);
    }

    // Clear the input value
    event.input.value = "";

    this.classCtrl.setValue(null);
  }
  remove(rmClass: string)
  {
    const index = this.myClasses.indexOf(rmClass);
    if (index >= 0) {
      this.classes.push(this.myClasses.splice(index, 1)[0]);
    }
    this.classCtrl.setValue("")
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.myClasses.push(event.option.viewValue);
    const index = this.classes.indexOf(event.option.viewValue);
    if (index >= 0) {
      this.classes.splice(index, 1);
    }
    this.classCtrl.setValue("")
  }

  onSubmit(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    this.authService.update(localStorage.getItem('userId'), {
      helpNeeded: this.myClasses,
      preferredTimes: {
        beforeSchool: form.value.before == true,
        duringSchool: form.value.during == true,
        afterSchool: form.value.after == true,
        evenings: form.value.evening == true,
        weekendMorning: form.value.morning == true,
        weekendAfternoon: form.value.afternoon == true,
        weekendEvening: form.value.weekendEvening == true,
        
      },
      needsTutor: form.value.when,
      onboardingCompletion: {
        "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": false,
          "add-picture": false,
          "tutor-recommendation": false
      }
    }).subscribe(res => {
      window.analytics.track("Classes Submitted",{
        classes: this.myClasses, 
        weekdayAvailability:{
          beforeSchool: form.value.before == true,
          duringSchool: form.value.during == true,
          afterSchool: form.value.after == true,
          evenings: form.value.evening == true,
        },
        weekendAvailability:{
          weekendMorning: form.value.morning == true,
          weekendAfternoon: form.value.afternoon == true,
          weekendEvening: form.value.weekendEvening == true
        },
        needsTutor: form.value.when

      });
      this.router.navigate(['onboarding/about-me'])
    })
    
  }
  skip()
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    this.authService.update(localStorage.getItem('userId'), {
        onboardingCompletion: {
          "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": false,
          "add-picture": false,
          "tutor-recommendation": false
        }
    }).subscribe(res => {
      window.analytics.track("Classes Skipped",{
        // id: localStorage.localStorage.getItem('userId')
      })
      this.router.navigate(['onboarding/about-me'])})
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.classes.filter(aClass => aClass.toLowerCase().includes(filterValue));
  }

}
