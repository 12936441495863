import {createAction, props } from '@ngrx/store'

export const login = createAction(
    '[Auth] Login',
    props<{email: string, password: string, remember: boolean}>()
);
export const loginFailure = createAction(
    '[Auth] Login Failed',
    props<{error: Object}>()
);
export const join = createAction(
    '[Auth] Join',
    props<{email: string, password: string, schoolCode: string}>()
);
export const joinFailure = createAction(
    '[Auth] Join Failed',
    props<{error: Object}>()
);
export const register = createAction(
    '[Auth] Register',
    props<{school: string, email: string, personalEmail:string, password: string, repassword: string, schoolCode: string}>()
);
export const logout = createAction('[Auth] Logout');
export const loginSucceed = createAction(
    '[Auth] Login Succeed',
    props<{token: string, parsedToken: any}>()
);
export const joinSucceed = createAction(
    '[Auth] join Succeed',
    props<{token: string, parsedToken: any}>()
);
export const registerSucceed = createAction(
    '[Auth] Register Succeed',
    props<{token: string, parsedToken: any}>()
)
export const profileCreation = createAction(
    '[Auth] Profile Creation',
    props<{firstName: string, lastName: string, schoolName: string, birthday: string, gender: string, enableSMS: boolean, phoneNumber: string, gradeLevel: number, studentId: string, referrer: string, parentEmail: string,enableParentEmail: boolean,  personalEmail: string, token: string}>()
)
export const profileCreationSucceed = createAction(
    '[Auth] Profile Creation',
    props<{token: string, parsedToken: any}>()
)
export const decipherToken = createAction(
    '[Auth] Decipher Token',
    props<{token: string, parsedToken: any}>()
)