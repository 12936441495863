import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { getLocaleDateTimeFormat } from '@angular/common';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { ViewChild } from '@angular/core';
import { SchoolService } from 'src/app/store/services/school.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css', '../onboarding.css']
  
})


export class PersonalInfoComponent implements OnInit {
  @ViewChild('personalForm',{static:false}) public personalForm: NgForm;
  tuteeImg = "../../../../../assets/img/onboarding-progress-personal-info-tutee.svg"
  tutorImg = "../../../../../assets/img/onboarding-progress-personal-info-tutor.svg"
  type: string
  pronounCtrl = new FormControl()
  pronouns = ["he/him", "she/her", "they/them", "other"]
  email: any;
  intraSchool: boolean = false
  requirePersonalEmail: any;
  

  constructor(private router: Router,
    private authService: AuthService,
    private store: Store<any>,
    private notificationService: NotificationsService,
    private schoolService: SchoolService) { }

  ngOnInit() {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);
      this.logout()
      return
    }
  this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
    if (res['confirmed']){
      this.router.navigate([''])
    }
    this.schoolService.getSchoolbyID(res['school']).subscribe(school =>{
      if(school['programType'] == 'Intra-School')
      {
        this.intraSchool = true
      }
      this.requirePersonalEmail = school['requirePersonalEmail']
    })
    this.type = res['type']
    console.log(this.type)
    this.email = res['email']
    this.personalForm.controls['first'].setValue(res['firstname'])
    this.personalForm.controls['last'].setValue(res['lastname'])
    this.personalForm.controls['birthday'].setValue(new Date(res['birthday']))
    this.personalForm.controls['personalEmail'].setValue(res['personalEmail'])
    this.personalForm.controls['parentEmail'].setValue(res['parentEmail'])
    this.personalForm.controls['phone'].setValue(res['phoneNumber'])
    this.personalForm.controls['sms'].setValue(res['enableSMS'])
    this.personalForm.controls['pronouns'].setValue(res['pronouns'])
    

  })}

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.pronounCtrl.setValue(event.option.value);
  }
  onSubmit(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }

    if(form.valid || form.value.parentEmail==this.email 
      || form.value.personalEmail==this.email 
      || form.value.personalEmail==form.value.parentEmail)
    {
      var parentEmail
      if(!this.intraSchool && this.type == 'Parent')
      {
        parentEmail = this.email
      }
      else{
        parentEmail = form.value.parentEmail
      }
      var personalEmail
      if (this.intraSchool && this.type != 'Parent' && this.requirePersonalEmail == false){
        personalEmail = this.email
      }
      else if(this.intraSchool && this.type == 'Parent' && (form.value.personalEmail == "" || form.value.personalEmail == undefined))
      {
        personalEmail = parentEmail
      }
      else
      {
        personalEmail = form.value.personalEmail
      }


      var birthday =new Date(form.value.birthday);
      var now = new Date();
      var birthday13 = new Date(birthday)
      birthday13.setFullYear(birthday13.getFullYear()+13)
      var parentApproval
      if(now < birthday13 && this.type != 'Parent')
      {
        parentApproval = false
      }
      else
      {
        parentApproval = true
      }
      
      var datestring =("0"+(birthday.getMonth()+1)).slice(-2) +"/" +  ("0" + birthday.getDate()).slice(-2) + "/" +   + birthday.getFullYear();
      this.authService.update(localStorage.getItem('userId'), 
      {
        firstname: form.value.first,
        lastname: form.value.last,
        birthday: datestring,
        personalEmail: personalEmail,
        parentEmail: parentEmail,
        phoneNumber: form.value.phone,
        pronouns: form.value.pronouns,
        approved: parentApproval,
        enableSMS: form.value.sms,
        onboardingCompletion: {
          "type-select": true,
        "school-info": true,
        "personal-info": true,
        "classes": false,
        "about-me": false,
        "add-picture": false,
        "tutor-recommendation": false
        }
        

        }).subscribe(res =>
        {
          if (this.type == 'Tutor')
          {
            this.router.navigate(['onboarding/about-me'])
          }
          else
          {

            window.analytics.track("Personal Info Submitted",{
              firstname: form.value.first,
              lastname: form.value.last,
              type: this.type,
              birthday: datestring,
              personalEmail: form.value.personalEmail,
              parentEmail: form.value.parentEmail,
              phoneNumber: form.value.phone,
              pronouns: form.value.pronouns,
              approved: parentApproval
            });
            this.router.navigate(['onboarding/classes'])
          }
        })
    }
  }
  goBack()
  {
    this.router.navigate(['onboarding/school-info'])
  }

}
