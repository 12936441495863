import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class TeachersService {

    constructor(private http: HttpClient) {
         this.getHamiltonTeachersJSON().subscribe(data => {
             console.log(data);
         });
         this.getMandarinTeachersJSON().subscribe(data => {
            console.log(data);
        });
         this.getTempeUnionTeachersJSON().subscribe(data => {
             console.log(data);
         });
     }
 
     public getHamiltonTeachersJSON(): Observable<any> {
         return this.http.get("./assets/Hamilton_Teachers.json");
     }
     public getMandarinTeachersJSON(): Observable<any> {
        return this.http.get("./assets/Mandarin_Teachers.json");
    }
    public getTempeUnionTeachersJSON(): Observable<any> {
        return this.http.get("./assets/Tempe_Union_Teachers.json");
    }
 }