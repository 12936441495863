import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http'
import { AuthEffects } from './store/effects/auth.effects';
import { authReducer } from './store/reducers/auth.reducers';
import { DashboardPageComponent,DeleteAvailabilityDialog, DeleteTutoringDialog, HelpDialog, DetailCourseDialog, StudentFeedbackDialog, TutorFeedbackDialog, TokenRefreshDialog, DetailSessionDialog, DetailNoteDialog, CancelConfirmDialog } from './components/pages/dashboard/dashboard-page/dashboard-page.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SearchPageComponent } from './components/pages/search/search-page/search-page.component';
import { ParentApprovalPageComponent } from './components/pages/parent-approval/parent-approval-page.component';


import { TeacherDashboardPageComponent, TeacherTokenRefreshDialog } from './components/pages/teacher/dashboard/teacher-dashboard-page.component';

import {AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import {DataGuardService as DataGuard} from './data/data-guard.service';
import {AppointmentsService} from './store/services/appointments.service';
import {SearchService} from './store/services/search.service';
import {ClassDetailService} from './store/services/classDetail.service';
import {AuthService} from './store/services/auth.service';
import {TeachersService} from './store/services/teacher.service';
import { ClassPageComponent,TutorsSelectDialog, PaidSessionConfirmDialog, SchoolSessionLimitDialog  } from './components/pages/class/class-page/class-page.component';
import { SchoolService } from './store/services/school.service';
import { AvailabilityService } from './store/services/availability.service';
import { ScheduleService } from './store/services/schedule.service';
import { schoolReducer } from './store/reducers/school.reducer';
import { SchoolEffects } from './store/effects/school.effect';
import { PhoneMaskDirective} from '../app/customs';
import {
  MatNativeDateModule,
  MatRippleModule,
  MatSelectModule,
  MatDialogModule,
  MatButtonModule,
  MatRadioModule,
  MatInputModule,
  MatIconModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatTableModule,
 } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import {DatePipe} from '@angular/common';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import * as  Cloudinary from 'cloudinary-core';
import { FileSelectDirective } from 'ng2-file-upload';
// import { RatingModule } from 'ng-starrating';
import { NgxStarsModule } from 'ngx-stars';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { LoginComponent } from './components/pages/login/login/login.component';
import { MatSliderModule, MatTabsModule} from '@angular/material';
import { MatCardModule } from '@angular/material';
import { TypeSelectComponent } from './components/pages/onboarding/type-select/type-select.component';
import { SchoolInfoComponent } from './components/pages/onboarding/school-info/school-info.component';
import { PersonalInfoComponent } from './components/pages/onboarding/personal-info/personal-info.component';
import { ClassesComponent } from './components/pages/onboarding/classes/classes.component';
import { AboutMeComponent } from './components/pages/onboarding/about-me/about-me.component';
import { AddPictureComponent } from './components/pages/onboarding/add-picture/add-picture.component';
import { SignupConfirmationComponent, UpdateEmailDialog } from './components/pages/onboarding/signup-confirmation/signup-confirmation.component';
import { VerificationConfirmationComponent } from './components/pages/onboarding/verification-confirmation/verification-confirmation.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PasswordResetComponent } from './components/pages/password-reset/password-reset.component';
import { TutorClassesComponent } from './components/pages/tutor-onboarding/tutor-classes/tutor-classes.component';
import { AvailabilitiesComponent } from './components/pages/tutor-onboarding/availabilities/availabilities.component';
import { JoinDialog } from './components/pages/join/join-dialog';
import { WarningDialog, WarningGuard } from './components/pages/tutor-onboarding/warning/warning';
import { TutorRecommendationComponent } from './components/pages/onboarding/tutor-recommendation/tutor-recommendation.component';

declare global {
  interface Window { analytics: any; }
}

@NgModule({
  declarations: [
    PhoneMaskDirective,
    AppComponent,
    DashboardPageComponent,
    TeacherDashboardPageComponent,
    DetailCourseDialog,
    CancelConfirmDialog,
    StudentFeedbackDialog,
    TutorFeedbackDialog,
    TokenRefreshDialog,
    TeacherTokenRefreshDialog,
    DetailSessionDialog,
    DetailNoteDialog,
    DeleteAvailabilityDialog,
    DeleteTutoringDialog,
    HelpDialog,
    TutorsSelectDialog,
    PaidSessionConfirmDialog,
    SchoolSessionLimitDialog,
    SearchPageComponent,
    ClassPageComponent,
    ParentApprovalPageComponent,
    FileSelectDirective,
    ProfileComponent,
    LoginComponent,
    TypeSelectComponent,
    SchoolInfoComponent,
    PersonalInfoComponent,
    ClassesComponent,
    AboutMeComponent,
    AddPictureComponent,
    SignupConfirmationComponent,
    VerificationConfirmationComponent,
    UpdateEmailDialog,
    PasswordResetComponent,
    TutorClassesComponent,
    AvailabilitiesComponent,
    JoinDialog,
    WarningDialog,
    TutorRecommendationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTableModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTabsModule,
    MatCardModule,
    MatSliderModule,
    AppRoutingModule,
    MatSlideToggleModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    // RatingModule, 
    NgxStarsModule,
    CloudinaryModule.forRoot(Cloudinary, { cloud_name: 'peersquared', upload_preset: 'ml_default', api_key: '824621857231961', api_secret: 'XSVyDjMAMWEVneWbzpZsofsaBsA'}),
    SimpleNotificationsModule.forRoot(),
    StoreModule.forRoot({
      auth: authReducer,
      schools: schoolReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([AuthEffects, SchoolEffects]),
    BrowserAnimationsModule
  ],
  exports: [
    PhoneMaskDirective,
    FileSelectDirective
  ],
  entryComponents: [
    DetailCourseDialog,
    CancelConfirmDialog,
    StudentFeedbackDialog,
    TutorFeedbackDialog,
    TokenRefreshDialog,
    DetailSessionDialog,
    DetailNoteDialog,
    DeleteAvailabilityDialog,
    DeleteTutoringDialog,
    HelpDialog,
    JoinDialog,
    TutorsSelectDialog,
    PaidSessionConfirmDialog,
    SchoolSessionLimitDialog,
    ClassPageComponent,
    TeacherTokenRefreshDialog,
    UpdateEmailDialog,
    WarningDialog,
  ],
  providers: [
    AuthGuard,
    DataGuard,
    WarningGuard,
    AppointmentsService,
    AvailabilityService,
    SearchService,
    ClassDetailService,
    AuthService,
    SchoolService,
    ScheduleService,
    TeachersService,
    DatePipe,
    NotificationsService,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
