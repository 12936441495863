import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { logout } from 'src/app/store/actions/auth.actions';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { AuthService } from 'src/app/store/services/auth.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { ChangeDetectorRef } from '@angular/core';
import { AvailabilityService } from 'src/app/store/services/availability.service';
import { HelpDialog } from '../../dashboard/dashboard-page/dashboard-page.component';



@Component({
  selector: 'app-availabilities',
  templateUrl: './availabilities.component.html',
  styleUrls: ['../../onboarding/onboarding.css', './availabilities.component.css']
})
export class AvailabilitiesComponent implements OnInit {
  [x: string]: any;
  userData: any;
  username: string;
  schoolObj: { result: Object; };
  availabilityParams: any;
  days: string[];
  startTimes = []
  selectedPref = {startTimes:[], endTimes:[], locations:[]};
  endTimes = [];
  selectedLocation: any;
  limitOptions: number[];
  selectedLimit: string;
  
  constructor(
    private cd: ChangeDetectorRef,
    private schoolService: SchoolService,
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private userService: AppointmentsService,
    private availabilityService: AvailabilityService,
    private appointmentsService: AppointmentsService
  ) { }
  schoolName
  isMaintenance
  dashLogo
  route
  saved = false
  selectedDay
  selectedStarts = [undefined]
  selectedEnds =[undefined]
  schoolId
  sessionLength
  
  
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.saved
  }
  ngOnInit() {
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];   
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => {      
      this.isMaintenance = data.result['maintenance']
      this.dashLogo = data.result['dashLogo']
      this.availabilityParams = data.result['availabilityParameters']
      this.sessionLength = data.result['duration']
      this.days = Object.keys(this.availabilityParams)
      this.schoolId = data.result['_id']
    })
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.goLoginPage();
      return
    }
    this.userService.getAppointments()
      .subscribe(data => {
        this.userData = data.result;
        // this.isMaintenance = this.userData.school.maintenance                        
        this.username = this.userData['firstname'] + ' ' + this.userData['lastname']
        // this.schoolID = this.userData['school']._id;
        // //This section is for account info div
        // this.type = this.userData['type']
        // this.grade = this.userData['grade']
        // this.enableSMS = this.userData['enableSMS']
        // this.email = this.userData['email']
        // this.dateCreated = this.userData['dateCreated'] // date object type may need some more looking into
        // this.birthday = this.userData['birthday']
        // this.firstName = this.userData['firstname']
        // this.gender = this.userData['gender']
        // this.lastName = this.userData['lastname']
        // this.parentEmail = this.userData['parentEmail']
        // this.personalEmail = this.userData['personalEmail']
        // this.phoneNumber = this.userData['phoneNumber']
        // this.studentID = this.userData['studentId']
        // this.referrer = this.userData['referrer']
        this.availabilities = this.userData['availability']
        this.availabilities = this.availabilities.filter(a => a.school == this.schoolId)
        let short_schoolName = this.userData['school'].shortName;        
        let schoolNames = [];
        this.userData['schools'].forEach(school =>{
          schoolNames.push(school.shortName)
        })
      })

  }
  onDayChange(day){
    this.startTimes[0] = []
    Object.keys(this.availabilityParams[day]).forEach(timePref => {
      this.startTimes[0] = this.startTimes[0].concat(this.availabilityParams[day][timePref].startTimes)
    })
    if(!this.startTimes[0].includes(this.selectedStarts[0]))
    {
      this.selectedStarts[0] = undefined
    }
    this.onStartChange(0)
  }

  onStartChange(i){
    
    Object.keys(this.availabilityParams[this.selectedDay]).forEach(timePref => {
      if (this.availabilityParams[this.selectedDay][timePref].startTimes.includes(this.selectedStarts[i]))
      {
        this.selectedPrefName = timePref
        this.selectedPref = this.availabilityParams[this.selectedDay][timePref]
      }
    })
    if(!this.selectedPref.locations.includes(this.selectedLocation) || i == undefined)
    {
      this.selectedLocation = undefined
    }
    this.endTimes[i] = []
    this.selectedPref.endTimes.forEach(endTime =>{
      if (moment(this.selectedStarts[i], "hh:mm a").diff(moment(endTime, "hh:mm a")) < 0)
      {
        this.endTimes[i].push(endTime)
      }
    })
    if(!this.endTimes[i].includes(this.selectedEnds[i]))
    {
      this.selectedEnds[i] = undefined
    }
    console.log(this.selectedStarts, this.selectedEnds)
    
    
    this.onEndChange(i)
  }

  onEndChange(i){
    if(i+1 < 3)
    {
      this.startTimes[i+1] = []
        this.selectedPref.startTimes.forEach(time => {
          if(moment(this.selectedEnds[i], "hh:mm a").diff(moment(time, "hh:mm a")) < 0){
            this.startTimes[i+1].push(time)
          }
        })
    }
    if(i+1 < this.selectedStarts.length)
    {
      
      if(!this.startTimes[i+1].includes(this.selectedStarts[i+1]))
      {
        this.selectedStarts[i+1] = undefined
      }
      this.onStartChange(i+1)
    }
    var maxSessions = 0
    for(let j = 0; j < this.selectedStarts.length; j++){
    maxSessions += moment(this.selectedEnds[j], "hh:mm a").diff(moment(this.selectedStarts[j], "hh:mm a")) / (1000 * 60) / this.sessionLength
    }
    this.limitOptions = Array.from({length: maxSessions + 1}, (x, i) => i)
    this.limitOptions.splice(0,1)
    this.selectedLimit = "" + this.limitOptions[this.limitOptions.length-1]
  }
  removeAvailabilities(i){
    this.availabilityService.deleteAvailability(this.availabilities[i]._id, 1).subscribe(res=>{})
    this.availabilities.splice(i,1)
  }

  addTimeBlock()
  {
    this.selectedStarts.push(undefined)
    this.selectedEnds.push(undefined)
  }
  removeTimeBlock(i)
  {
    this.selectedStarts.splice(i,1)
    this.selectedEnds.splice(i,1)
    console.log(this.selectedStarts, this.selectedEnds)
  }

  addAvailability(){
    this.appointmentsService.registerTutor(this.selectedStarts, this.selectedEnds, true, this.selectedDay, this.selectedPrefName, this.selectedLocation, this.sessionLength, this.userData.courses[0]._id, this.schoolId, parseInt(this.selectedLimit)).subscribe(res =>{
      this.appointmentsService.getAppointments().subscribe(user =>
        this.availabilities = user.result['availability'].filter(a=>a.school == this.schoolId))
    })
  }
  
  onAvailabilityClick(i){
    this.selectedDay = this.availabilities[i].dayOfWeek
    this.onDayChange(this.selectedDay)
    this.selectedStarts = this.availabilities[i].startTimes
    this.selectedEnds = this.availabilities[i].endTimes
    this.onStartChange(0)
    this.selectedLocation = this.availabilities[i].location[0]
    this.selectedLimit = this.availabilities[i].dayLimit

  }

  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }
  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }
  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  goToOnboarding(){
    this.router.navigate(['/school', this.schoolName, 'tutor-onboarding', 'classes'])
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['/school', this.schoolName, 'login']);  
    localStorage.setItem('destLink', 'search')
  }
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }
  onSubmit(){
    this.authService.update(localStorage.getItem("userId"), {active: true}).subscribe(()=>{})
    this.saved = true
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
}
