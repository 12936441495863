import { Component, OnInit,Inject, Input, NgZone, HostListener, ElementRef, ViewChild} from '@angular/core';
import {Store, select} from '@ngrx/store'
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {Observable, Subscription, throwError} from 'rxjs'
import {map, startWith} from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { ClassDetailService } from 'src/app/store/services/classDetail.service';
import { SchoolService } from 'src/app/store/services/school.service';
import { SearchService } from 'src/app/store/services/search.service';
import { AvailabilityService } from 'src/app/store/services/availability.service';
import { ScheduleService } from 'src/app/store/services/schedule.service';
import {TeachersService } from 'src/app/store/services/teacher.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationsService } from 'angular2-notifications'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { HttpClient } from '@angular/common/http';
import { logout } from 'src/app/store/actions/auth.actions';
import { AuthService } from 'src/app/store/services/auth.service';
import { ShortSchoolName} from 'src/app/common'
import { HelpDialog} from '../../dashboard/dashboard-page/dashboard-page.component';

import { DatePipe } from '@angular/common';
import { openJoinDialog } from '../../join/join-dialog';
declare var $: any;
export interface DialogData {    
  tutor: any,
  tutors: [];
  classname: string,
  time: string,
  location: string,
  teacher: string,
  teachers: [],
  help: string,
  helps: [],
  comment: string,
  date: string,
  requireDetails: boolean,
  detailsMissing: boolean

}
export interface PaidSessionData {    
  timestamp: number,
}
@Component({
  selector: 'app-class-page',
  templateUrl: './class-page.component.html',
  styleUrls: ['./class-page.component.css']
})
export class ClassPageComponent implements OnInit {
  selectorOpen: boolean;
  programs: any[];
  closefunction: any;
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;    
  }
  @BlockUI() blockUI: NgBlockUI;
  mainState: String;
  isAvailability: Boolean;
  isApply: Boolean;
  classID = '';    
  route: ActivatedRouteSnapshot;
  userData = null;
  schoolID = '';
  schoolName = '';
  dashLogo = '';
  username = '';
  dayOfWeek = 'Monday';
  dayOfWeeks = [];
  availabilityParameters = {};
  preference = "";
  location = [];
  locations = [];
  startTime = ''
  endTime = '';
  useGlobal = false;
  allAvailabilities = {};
  schoolData = null;
  startTimeList = [];
  endTimeList = [];
  timeErrorEnable = false;
  timeRangeErrorEnable = false;
  isUpdated = false;
  classData = null;
  nextSessionDate = null;
  nextSessionList = [];
  tempNextSessionList = [];
  nextSessionWeekdays = {}
  nextSessionDates = [];
  sessionIdx = null;
  tutorIdx = null;
  allTeachers = [];
  timeRangeList = [];
  startTimes = [];
  endTimes = [];
  innerWidth: any;
  dayLimit = 1;
  dayLimits = [];
  schoolNames = [];
  isMaintenance = false;  
  requireDetails = false;
  selectedTutor = null
  allTutors = []; 
  activeTutors = [];
  duration = 30
  isValidateForm = false

  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  locationCtrl = new FormControl();
  filteredLocations: Observable<any[]>;
  separatorKeysCodes = [];

  @ViewChild('locationInput', { static: true }) locationInput: ElementRef;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private classDetailService: ClassDetailService,
    private userService: AppointmentsService,
    private schoolService: SchoolService,
    private classService: SearchService,
    private availabilityService:  AvailabilityService,
    private scheduleService:  ScheduleService,
    private teacherService:  TeachersService,
    private notificationService: NotificationsService,
    private datePipe: DatePipe,
    public dialog: MatDialog,  
    private authService: AuthService
  ){
    this.filteredLocations = this.locationCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this.filter(item) : this.locations.slice()));
  }
  openJoinDialog = openJoinDialog
  ngOnInit() {
    this.route = this.activatedRoute.snapshot;
    this.classID = this.route.params['classID'];    
    this.schoolName = this.route.params['schoolID'];
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.goLoginPage()
      return
    }
    localStorage.removeItem('destLink')
    
    this.getSchoolData()
        
    this.innerWidth = window.innerWidth;    
    this.getHamilonTeachers()
    this.mainState = "availability";
    this.isAvailability = true;
    this.isApply = false;
    this.isUpdated = false;
    this.dayLimits.push(1)

    this.userService.getAppointments()
      .subscribe(data => {
        this.userData = data.result;
        this.username = this.userData.firstname + ' ' + this.userData.lastname
        let short_schoolName = this.userData.school.shortName;        
        let schoolNames = [];
        this.userData.schools.forEach(school =>{
          schoolNames.push(school.shortName)
        })
                
        let isExist = schoolNames.includes(this.schoolName);                             

        this.schoolID = this.userData.school._id;        

        if (this.schoolName == this.userData.school.shortName){          
          this.schoolID = this.userData.school._id;      
        }else {
          this.userData.schools.forEach(school =>{
            if (this.schoolName == school.shortName){
              this.schoolID = school._id;                          
            }
          })
        }
        
        if (short_schoolName != this.schoolName && !isExist){
          this.notificationService.error('Error', 'You do not have access to this school.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
          this.logout();
          this.router.navigate(['/school', this.schoolName, 'join']);
        }

        this.getAvailabilityByID()

        let currentDate = new Date()            
        let diffDate = currentDate.setHours(currentDate.getHours() + 12)
        this.getClassDetail()
    }, error => {
      if (error.error == 'Unauthorized'){
        this.goLoginPage();
      }
    })
  }
  openSelector(){
    if(this.selectorOpen != true){
      this.userService.getAppointments().subscribe(data => {
          this.userData = data.result;
        })
      this.programs = []
      this.programs.push(this.userData.school)
      this.programs = this.programs.concat(this.userData.schools)
      this.selectorOpen = true
      this.closefunction = this.closeSelector.bind(this)
      setTimeout(()=>
      window.addEventListener('click', this.closefunction), 10)
    }
  }
  closeSelector(e:MouseEvent) {
    if(document.getElementById('program-selector')!=null){
    if(!document.getElementById('program-selector').contains(e.target as Node)) {
        console.log(this)
        this.selectorOpen = false
        console.log(this.selectorOpen)
        window.removeEventListener('click', this.closefunction)
    } }
  }
  switchSchools(school)
  {
    this.router.navigate(['school', school,'search']).then(()=>
    window.location.reload())
  }  

  getSchoolData() {
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => { 
      this.schoolData = data.result;
      this.availabilityParameters = this.schoolData.availabilityParameters

      //get next session blank appointments
      this.getNextAppointments(this.schoolData._id, 'all')

      this.isMaintenance = this.schoolData.maintenance;
      this.dashLogo = this.schoolData.dashLogo
      this.requireDetails = this.schoolData.requireDetails;
      this.duration = this.schoolData.duration;

      this.dayOfWeeks = Object.keys(this.availabilityParameters)
      this.dayOfWeek = this.dayOfWeeks[0];

      let tempStartTimes = []
      for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
        tempStartTimes = [...tempStartTimes, ...value["startTimes"]]
      }

      this.preference = "";
      this.locations = []
      this.startTimeList = tempStartTimes.filter((item, index) => tempStartTimes.indexOf(item) === index)
      this.endTimeList = []
      this.endTimeList = []      ;
      this.location = []
      this.startTimes= this.startTimeList[0]
      this.endTimes = [];  
      this.startTime = "";
      this.endTime = "";
      this.timeRangeList = [];

      let time_range = {
        startTimes: this.startTimeList,
        endTimes: this.endTimeList
      }
      this.timeRangeList.push(time_range);
    })
  }

  getNextAppointments(id, tutor) {
    this.scheduleService.getNextAppointments(id, this.classID, tutor).subscribe(appointments => {
      this.nextSessionList = appointments.result['data'];
      if(tutor == 'all') this.activeTutors = appointments.result['tutors'];
      console.log('next appointment', appointments.result)
    })
  }

  getAvailabilityByID(){ 
    this.availabilityService.getAvailabilitiesByID(this.schoolID).subscribe(data =>{
      console.log("all available", data.result)
      let result = null;
      result = data.result;
      result.forEach(element => {
        if(this.allAvailabilities[element.dayOfWeek]) {
          this.allAvailabilities[element.dayOfWeek][element.timePreference] = {
            useGlobal: true,
            startTimes: element.startTimes,
            endTimes: element.endTimes,
            location: element.location
          }
        } else {
          if(element.timePreference) {
            this.allAvailabilities[element.dayOfWeek] = {
              [element.timePreference]: {
                useGlobal: true,
                startTimes: element.startTimes,
                endTimes: element.endTimes,
                location: element.location
              }
            } 
          }          
        }    
      });
      console.log("new useGlobal", this.allAvailabilities)
      let tempStartTimes = []
      for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
        tempStartTimes = [...tempStartTimes, ...value["startTimes"]]
      }

      this.preference = ""
      this.locations = []
      this.startTimeList = tempStartTimes.filter((item, index) => tempStartTimes.indexOf(item) === index)
      this.endTimeList = []
      this.location = []
      this.startTime = ""
      this.endTime = "";
      this.startTimes = [];
      this.endTimes = [];
  
      // this.useGlobal = this.allAvailabilities[this.dayOfWeek] ? this.allAvailabilities[this.dayOfWeek].useGlobal : false
      
      // if(this.allAvailabilities[this.dayOfWeek]) {
      //   let tempAvailabilities = this.allAvailabilities[this.dayOfWeek]        
      //   for(let i = 0; i < tempAvailabilities.startTimes.length; i++) {
      //     if(this.startTimeList.includes(tempAvailabilities.startTimes[i])) {
      //       this.startTimes.push(tempAvailabilities.startTimes[i])
      //       this.endTimes.push(tempAvailabilities.endTimes[i])
      //     }
      //   }
      // }

      // if(this.startTimes.length == 0) {
        this.startTimes = [this.startTime]
        this.endTimes = [this.endTime]
      // } else {
      //   this.location = this.allAvailabilities[this.dayOfWeek].location
      // }

      this.timeRangeList = [];
      for(let i=0; i < this.startTimes.length; i++) {  
        let time_range = {
          startTimes: this.startTimeList,
          endTimes: this.endTimeList
        }
        this.timeRangeList.push(time_range);
      }
      this.calculateDayLimit()
    }, error => {
      if (error.status == 401){
        this.logout();
        this.router.navigate(['/school', this.schoolName, 'login']);  
      }
    })
  }

  addTimeRange(){
    if (this.timeRangeList.length >= 3){
      return
    }

    this.startTimeList = []
    for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
      if(key == this.preference) this.startTimeList = value["startTimes"]
    }

    let time_range = {
      startTimes: this.startTimeList,
      endTimes: this.endTimeList
    }
    this.timeRangeList.push(time_range);
    this.timeRangeList[0].startTimes = this.startTimeList       
    
    this.startTimes.push(this.startTimeList[0]);
    this.endTimes.push(this.endTimeList[0])
  }

  removeTimeRange(index){
    this.timeRangeList.splice(index, 1);
    this.startTimes.splice(index, 1);
    this.endTimes.splice(index, 1);
    if(this.timeRangeList.length == 1) {
      let tempStartTimes = []
      for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
        tempStartTimes = [...tempStartTimes, ...value["startTimes"]]
      }
      this.startTimeList = tempStartTimes.filter((item, index) => tempStartTimes.indexOf(item) === index)
      this.timeRangeList[0].startTimes = this.startTimeList 
    }
  }

  getHamilonTeachers(){
    this.allTeachers = [];
    this.teacherService.getHamiltonTeachersJSON().subscribe(data => {      
      this.allTeachers = data;      
    })    
  }

  getMandarinTeachers(){
    this.allTeachers = [];
    this.teacherService.getMandarinTeachersJSON().subscribe(data => {      
      this.allTeachers = data;      
    })    
  }

  getTempeUnionTeachers(){
    this.allTeachers = [];
    this.teacherService.getTempeUnionTeachersJSON().subscribe(data => {      
      this.allTeachers = data;  
      })      
  }

  getClassDetail(){
    this.classDetailService.getClassByID(this.classID).subscribe( data => {
      console.log("get class detail", data)  
      this.classData = data.result['content'];
    }, error => {
      if (error.error == 'Unauthorized'){
        this.goLoginPage()
      }
    })
  }

  changeMainTab(buttonState: String) {
    this.mainState = buttonState;
    if (buttonState == "availability") {
      this.isAvailability = true;
      this.isApply = false;
    } else if (buttonState == "apply") {
      this.isAvailability = false;
      this.isApply = true;
    }
  }

  checkvalidateForm() {
    if(this.startTimes.length == 0 || this.endTimes.length == 0 || this.dayOfWeek == '' || this.location.length == 0 || this.dayLimit == 0) {
      this.isValidateForm = true;
    } else {
      this.isValidateForm = false
    }
  }

  onSubmit(){        
    for (let i = 0; i < this.timeRangeList.length; i++){
      this.checkDuplicationTime(i)
    }

    if (this.timeErrorEnable || this.timeRangeErrorEnable){
      return
    }
    
    let duration =  0;
    for(let i=0; i < this.startTimes.length; i++) {
      duration += this.validateTime(i)
    }
    if(!duration || isNaN(duration)) return
    this.checkvalidateForm();
    if(this.isValidateForm) return;
    this.isUpdated = false
    this.blockUI.start()
    console.log(this.preference);
    this.userService.registerTutor(this.startTimes, this.endTimes, this.useGlobal, this.dayOfWeek, this.preference, this.location, duration, this.classID, this.schoolID, this.dayLimit).subscribe( data => {      
      this.blockUI.stop()
      this.classService.updateClass(this.classID, this.schoolID).subscribe( data =>{
        this.notificationService.success('Success', 'Confirmed Registration.', {
          timeOut: 6000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        });    
        this.getClassDetail()
        if(this.selectedTutor) {
          this.getNextAppointments(this.schoolData._id, this.selectedTutor._id); 
        } else {
          this.getNextAppointments(this.schoolData._id, 'all')
        }
        this.useGlobal = false
        this.getAvailabilityByID()
        this.blockUI.stop()
      }, error => {
        this.blockUI.stop()
      })
    }, error =>{
      this.blockUI.stop();
      if (error.error == 'Unauthorized'){
        this.router.navigate(['/school', this.schoolName, 'login']);  
      }
    })    
  }

  onTutorSelect(){        
    console.log("selected tutor", this.selectedTutor)
    this.getNextAppointments(this.schoolData._id, this.selectedTutor._id); 
  }

  onDayOfWeekSelection(){
    let tempStartTimes = []
    for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
      tempStartTimes = [...tempStartTimes, ...value["startTimes"]]
    }

    this.preference = ""
    this.locations = []
    this.startTimeList = tempStartTimes.filter((item, index) => tempStartTimes.indexOf(item) === index)
    this.endTimeList = []     
    this.location = []
    this.startTime = ""
    this.endTime = "";
    this.startTimes = [];
    this.endTimes = [];

    // this.useGlobal = this.allAvailabilities[this.dayOfWeek] ? this.allAvailabilities[this.dayOfWeek].useGlobal : false
    
    // if(this.allAvailabilities[this.dayOfWeek]) {
    //   let tempAvailabilities = this.allAvailabilities[this.dayOfWeek]
    //   for(let i = 0; i < tempAvailabilities.startTimes.length; i++) {
    //     if(this.startTimeList.includes(tempAvailabilities.startTimes[i])) {
    //       this.startTimes.push(tempAvailabilities.startTimes[i])
    //       this.endTimes.push(tempAvailabilities.endTimes[i])
    //     }
    //   }
    // }
    // if(this.startTimes.length == 0) {
      this.startTimes = [this.startTime]
      this.endTimes = [this.endTime]
    // } else {
    //   this.location = this.allAvailabilities[this.dayOfWeek].location
    // }

    this.timeRangeList = [];
    for(let i=0; i < this.startTimes.length; i++) {  
      let time_range = {
        startTimes: this.startTimeList,
        endTimes: this.endTimeList
      }
      this.timeRangeList.push(time_range);
    }
    this.calculateDayLimit()
    if (this.schoolName != ShortSchoolName.hamilton_high_school){
      return
    }    
  }

  onLocationSelection(){
    if (this.schoolName != ShortSchoolName.hamilton_high_school){
      return
    }    
  }

  add(event: MatChipInputEvent): void {
    this.isValidateForm = false
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      if(!this.location.includes(value.trim()) && this.locations.includes(value.trim())) this.location.push(value.trim());
    }
    if (input) {
      input.value = '';
    }

    this.locationCtrl.setValue(null);
  }

  filter(name: string) {
    return this.locations.filter(item =>
        item.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  removeLocation(item: string): void {
    const index = this.location.indexOf(item);

    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    this.isValidateForm = false
    if(!this.location.includes(event.option.viewValue)) this.location.push(event.option.viewValue);
  }

  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }

  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }

  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }

  goLoginPage(){
    this.logout();            
    this.router.navigate(['/school', this.schoolName, 'login']);  
    localStorage.setItem('destLink', this.classID);              
  }

  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }

  onStartTimeSelection(i){
    for(const [key, value] of Object.entries(this.availabilityParameters[this.dayOfWeek])) {
      if(value["startTimes"].includes(this.startTimes[i])) {
        this.preference = key;
        this.locations = value["locations"]
        this.endTimeList = value["endTimes"]
      }
    }
    console.log("useGlobal", this.allAvailabilities);
    console.log("preference", this.preference);
    this.useGlobal = this.allAvailabilities[this.dayOfWeek] ? this.allAvailabilities[this.dayOfWeek][this.preference] ? this.allAvailabilities[this.dayOfWeek][this.preference].useGlobal : false : false
    this.timeRangeList[i].endTimes = this.endTimeList;

    let duration=  this.validateTime(i);
    if (duration != null){
      this.calculateDayLimit()
    }
    this.checkDuplicationTime(i)  
  }
  
  onEndTimeSelection(i){
    this.isValidateForm = false;
    let duration = this.validateTime(i);    
    if (duration != null){
      this.calculateDayLimit()
    }
    
    this.checkDuplicationTime(i)  
  }

  calculateDayLimit(){    
    let maxDayLimit = 0;
    for (let i = 0; i < this.startTimes.length; i++){    
      let duration = this.validateTime(i); 
      let dayLimit = Math.floor(duration / this.duration);
      if(dayLimit == 0) dayLimit = 1
      maxDayLimit += dayLimit;
    }
    
    this.dayLimits = [];
    for (let i = 1; i <= maxDayLimit; i++){
      this.dayLimits.push(i)
    }
    
    this.dayLimit = maxDayLimit
  }

  checkDuplicationTime(i){
    if (this.startTimes.length == 1){
      return;
    }
    this.timeRangeErrorEnable = false;    
    let startTime = this.startTimes[i];
    let endTime = this.endTimes[i];

    let start_time_unit = startTime.substring(startTime.length - 2);
    let end_time_unit = endTime.substring(endTime.length - 2);    
    let selectedStartTimeNumber = 0
    let selectedEndTimeNumber = 0

    if (start_time_unit == 'AM' || startTime.split(':')[0] == '12'){
      selectedStartTimeNumber = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1])
    }else {
      selectedStartTimeNumber = (parseInt(startTime.split(':')[0]) + 12) * 60 + parseInt(startTime.split(':')[1])
    }

    if (end_time_unit == 'AM' || endTime.split(':')[0] == '12'){
      selectedEndTimeNumber = parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1])
    }else {
      selectedEndTimeNumber = (parseInt(endTime.split(':')[0]) + 12) * 60 + parseInt(endTime.split(':')[1])
    }


    // selectedStartTimeNumber = parseInt(this.startTimes[i].split(':')[0]) * 60 + parseInt(this.startTimes[i].split(':')[1])
    // selectedEndTimeNumber = parseInt(this.endTimes[i].split(':')[0]) * 60 + parseInt(this.endTimes[i].split(':')[1])    
    for (let idx = 0; idx < this.startTimes.length; idx++){
      if (idx == i){
        continue
      }

      let startTimeIdx = this.startTimes[idx];
      let endTimeIdx = this.endTimes[idx];

      let start_number_unit = startTimeIdx.substring(startTimeIdx.length - 2);
      let end_number_unit = endTimeIdx.substring(endTimeIdx.length - 2);    
      let startNumber = 0
      let endNumber = 0

      if (start_number_unit == 'AM' || startTimeIdx.split(':')[0] == '12'){
        startNumber = parseInt(startTimeIdx.split(':')[0]) * 60 + parseInt(startTimeIdx.split(':')[1])
      }else {
        startNumber = (parseInt(startTimeIdx.split(':')[0]) + 12) * 60 + parseInt(startTimeIdx.split(':')[1])
      }

      if (end_number_unit == 'AM' || endTimeIdx.split(':')[0] == '12'){
        endNumber = parseInt(endTimeIdx.split(':')[0]) * 60 + parseInt(endTimeIdx.split(':')[1])
      }else {
        endNumber = (parseInt(endTimeIdx.split(':')[0]) + 12) * 60 + parseInt(endTimeIdx.split(':')[1])
      }

      // startNumber = parseInt(this.startTimes[idx].split(':')[0]) * 60 + parseInt(this.startTimes[idx].split(':')[1])
      // endNumber = parseInt(this.endTimes[idx].split(':')[0]) * 60 + parseInt(this.endTimes[idx].split(':')[1])

      if ((startNumber < selectedStartTimeNumber && selectedStartTimeNumber < endNumber) || (startNumber < selectedEndTimeNumber && selectedEndTimeNumber < endNumber)){        
        this.timeRangeErrorEnable = true;
        break
      }
      if ((startNumber < selectedStartTimeNumber && selectedStartTimeNumber < endNumber) && (startNumber < selectedEndTimeNumber && selectedEndTimeNumber > endNumber)){      
        this.timeRangeErrorEnable = true;
        break
      }  
      if (startNumber == selectedStartTimeNumber && endNumber == selectedEndTimeNumber){
        this.timeRangeErrorEnable = true;
        break
      }    
    }
  } 

  validateTime(i){
    this.timeErrorEnable = false;  
    let startTime = this.startTimes[i];
    let endTime = this.endTimes[i];

    let start_time_unit = startTime.substring(startTime.length - 2);
    let end_time_unit = endTime.substring(endTime.length - 2);    
    let startTimeNumber = 0;
    let endTimeNumber = 0;
    if (start_time_unit == 'AM' || startTime.split(':')[0] == '12'){
      startTimeNumber = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1])
    }else {
      startTimeNumber = (parseInt(startTime.split(':')[0]) + 12) * 60 + parseInt(startTime.split(':')[1])
    }

    if (end_time_unit == 'AM' || endTime.split(':')[0] == '12'){
      endTimeNumber = parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1])
    }else {
      endTimeNumber = (parseInt(endTime.split(':')[0]) + 12) * 60 + parseInt(endTime.split(':')[1])
    }    

    if (startTimeNumber >= endTimeNumber){
      this.timeErrorEnable = true      
      return
    } else {
      this.timeErrorEnable = false      
      return endTimeNumber - startTimeNumber      
    }
  }

  checkDate(tutors){    
    let keyArray = Object.keys(tutors);
    let tutor_count = 0
    Object.keys(tutors).map(k => {
      if (tutors[k].length > 0){
        tutor_count ++
      }
    })  
    if (tutor_count > 0){
      return true
    }else {
      return false
    }
    
  }

  bookSession(sessionIdx, time){
    let tutors = this.nextSessionList[sessionIdx].tutors[time]    
    this.sessionIdx = sessionIdx;
    this.tutorIdx = time; 
    let location = this.nextSessionList[this.sessionIdx].tutors[this.tutorIdx][0].availability.location;
    let classname = this.classData.name  

    window.analytics.track("Book Session Opened",{
      time:time,
      date:this.nextSessionList[this.sessionIdx].date,
      tutorCount:tutors.length,
      subject:classname
    });
    
    let teachers = [];        
    if (this.schoolName != ShortSchoolName.peersquared_provides_summer_tutoring && this.schoolName != ShortSchoolName.private_tutoring && this.schoolName != ShortSchoolName.brainstem_peer_tutoring){
      teachers = this.allTeachers[this.classData.name.trim()]    
      if (teachers != undefined && teachers.length == 0){
        teachers = this.allTeachers[this.classData.name]
      } 
      if (teachers == undefined){
        teachers = []
      }
    }        

    let helps = ['Homework help', 'Test prep', 'Class content', 'Get organized', 'Other'];

    let date = this.nextSessionList[this.sessionIdx].date; 
    if (this.schoolName == ShortSchoolName.mandarin){        
      this.scheduleService.getSessions(this.schoolID, this.userData['_id'], 7).subscribe(session =>{      
        if (Object.keys(session['result']).length < 4){                
          let dialog_height = '70%';
          if (this.innerWidth < 450){
            dialog_height = '200%'
          }                 
          const dialogRef = this.dialog.open(TutorsSelectDialog, {      
            data: {teacher: teachers[0], teachers: teachers,  tutor: tutors[0], tutors: tutors, help: 'Homework help', helps: helps, comment: '', time: time, classname: classname, location: location[0], date: date, requireDetails: this.schoolData.requireDetails},
            height: dialog_height
          });
      
          dialogRef.afterClosed().subscribe(result => {      
            if (result != undefined || result != null){
              let data = result.event;        
              this.sendBookRequest(data.tutor, data.teacher, data.location, data.help, data.comment, data.fileList)
            }      
          });
        }else {
          let timestamp = session.result[0].timestamp + 86400000 * 14;
          const dialogRef = this.dialog.open(SchoolSessionLimitDialog, {data: {timestamp: timestamp}, width: '500px'});                    
        }
      });    
    }else if (this.schoolName == ShortSchoolName.peersquared_provides_summer_tutoring)  {
      this.scheduleService.getSessions(this.schoolID, this.userData['_id'], 14).subscribe(session =>{                    
        if (Object.keys(session['result']).length < 2){                
            let dialog_height = '70%';
            if (this.innerWidth < 450){
              dialog_height = '200%'
            }
            const dialogRef = this.dialog.open(TutorsSelectDialog, {      
              data: {teacher: teachers[0], teachers: teachers,  tutor: tutors[0], tutors: tutors, help: 'Homework help', helps: helps, comment: '', time: time, classname: classname, location: location[0], date: date, requireDetails: this.schoolData.requireDetails},
              height: dialog_height
            });
        
            dialogRef.afterClosed().subscribe(result => {      
              if (result != undefined || result != null){
                let data = result.event;        
                this.sendBookRequest(data.tutor, data.teacher, data.location, data.help, data.comment, data.fileList)
              }      
            });
        }else {                     
          let timestamp = session.result[0].timestamp + 86400000 * 14;
          const dialogRef = this.dialog.open(PaidSessionConfirmDialog, {data: {timestamp: timestamp}, width: '500px'});
      
          dialogRef.afterClosed().subscribe(result => {      
            if (result != undefined || result != null){                    
              this.logout()              
              // this.router.navigate(['/school', 'private_tutoring', 'join', this.userData._id]);  
              let private_school = ShortSchoolName.private_tutoring;
              let isExist = this.schoolNames.includes(private_school);      
              if (isExist){                      
                this.router.navigate(['/school', 'private_tutoring', 'login']);  
              }else {
                this.router.navigate(['/school', 'private_tutoring', 'join']);
              }
              
            }      
          });
        }        
      }, error=> {
      })
    } else {
      let dialog_height = '70%';
      if (this.innerWidth < 450){
        dialog_height = '200%'
      }                 
      const dialogRef = this.dialog.open(TutorsSelectDialog, {      
        data: {teacher: teachers[0], teachers: teachers,  tutor: tutors[0], tutors: tutors, help: 'Homework help', helps: helps, comment: '', time: time, classname: classname, location: location[0], date: date, requireDetails: this.schoolData.requireDetails},
        height: dialog_height
      });
  
      dialogRef.afterClosed().subscribe(result => {      
        if (result != undefined || result != null){
          let data = result.event;
          this.sendBookRequest(data.tutor, data.teacher, data.location, data.help, data.comment, data.fileList)
        }      
      });
    }   
  }

  sendBookRequest(tutor, teacher, location, help, comment, fileList){
    this.blockUI.start()
    this.scheduleService.requestBookSession(this.classID, this.schoolID, tutor.tutor._id, tutor._id, location, teacher, help, comment, fileList).subscribe( result =>{
      this.blockUI.stop()

      this.classDetailService.getClassByID(this.classID).subscribe( data => {
        let classData = data.result['content'];
        window.analytics.track("Book Session Submitted",{
          tutor:tutor,
          teacher:teacher,
          location:location,
          help:help,
          subject: classData
        });
      })
      
      

      this.notificationService.success('Success', 'Session request successfully sent to tutor! Keep an eye on your email.', {
        timeOut: 2000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });

      this.getClassDetail()
      if(this.selectedTutor) {
        this.getNextAppointments(this.schoolData._id, this.selectedTutor._id); 
      } else {
        this.getNextAppointments(this.schoolData._id, 'all')
      } 
      this.getAvailabilityByID()
    }, error => {
      this.blockUI.stop();
      if (error.error == 'Unauthorized'){
        this.router.navigate(['/school', this.schoolName, 'login']);  
      }
    })       
  }

  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
}

@Component({
  selector: 'paid-session-confirm-dialog',
  templateUrl: 'paid-session-confirm-dialog.html',
})
export class PaidSessionConfirmDialog {
  constructor(    
    public dialogRef: MatDialogRef<PaidSessionConfirmDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: PaidSessionData) {
      dialogRef.disableClose = true;
    }

  onNo(): void {    
    this.dialogRef.close();
  }
  onYes(){
    let data = {
      status: true,    
    }    
    this.dialogRef.close({event: data});    
  }
}

@Component({
  selector: 'school-session-limit-dialog',
  templateUrl: 'school-session-limit-dialog.html',
})
export class SchoolSessionLimitDialog {
  constructor(    
    public dialogRef: MatDialogRef<SchoolSessionLimitDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: PaidSessionData) {
      dialogRef.disableClose = true;
    }
  onYes(){        
    this.dialogRef.close();    
  }
}

@Component({
  selector: 'tutors-select-dialog',
  templateUrl: 'tutors-select-dialog.html',
  styleUrls: ['tutors-select-dialog.css']
})
export class TutorsSelectDialog implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @Input()  
  private hasBaseDropZoneOver: boolean = false;
  private uploader: FileUploader;
  private fileName: string;
  private fileURL: string;
  private fileItem: any;
  private detailsMissing: boolean;
  private tutorRequired: boolean;
  private locationRequired: boolean;
  private fileList = [];
  private fileNameList = [];
  private requireDetails = true;
  

  constructor(
    private cloudinary: Cloudinary,
    private zone: NgZone,
    private http: HttpClient,
    private scheduleService:  ScheduleService,
    public dialogRef: MatDialogRef<TutorsSelectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.fileList = [];
      this.fileName = '';
      this.fileURL = '';
      this.detailsMissing = false;
      this.tutorRequired = false;
      this.locationRequired = false;
      this.requireDetails = data.requireDetails;     
    }

  ngOnInit() {
    console.log('dialog data', this.data)
    if(this.data.tutor.availability.location.length > 1) this.data.location = ""

    if(this.data.help) {
      this.detailsMissing = false;
    }
    
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
      // Upload files automatically upon addition to upload queue
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {    
      form.append('upload_preset', this.cloudinary.config().upload_preset);      
      form.append('file', fileItem);
      this.fileItem = fileItem;
      this.fileName = this.fileItem.file.name
      fileItem.withCredentials = false;
      return { fileItem, form };
    };
    this.uploader.onSuccessItem = (item: any, response: string): any => {          
      this.blockUI.stop()
      this.fileURL = JSON.parse(response).secure_url;
      this.fileList.push(this.fileURL);
      let split_url = this.fileURL.split('/')

      let filename = split_url[split_url.length - 1];
      this.fileNameList.push(filename)      
    }    
    this.uploader.onErrorItem = (item: any, response: string): any => {}    
    this.uploader.onProgressItem = (item: any, progress: any): any => {}
    this.uploader.onBeforeUploadItem = (item: any): any => {
      this.blockUI.start()      
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  upload() {
    if (this.fileNameList.length < 3){
      $('#upload_input').click();    
    }    
  }  

  onClose(): void {
    this.dialogRef.close({event: null});
    this.dialogRef.close();
  }

  radioChange(event){
    this.detailsMissing = false;
  }

  tutorChange(event){
    this.tutorRequired = false;       
  }

  locationChanged(event) {
    this.locationRequired = false;
  }

  sendBookRequest(teacher, tutor, location, help, comment){
    this.detailsMissing = false;
    this.tutorRequired = false;
    this.locationRequired = false;

    if (tutor == ''){
      this.tutorRequired = true;
      return
    }

    if (location == ''){
      this.locationRequired = true;
      return
    }

    if (this.requireDetails && comment == '' && this.fileList.length == 0){
      this.detailsMissing = true;
      return
    }

    if (!this.requireDetails && help == 'Other' && comment == '' && this.fileList.length == 0){
      this.detailsMissing =  true;
      return
    }
    
    let data = {
      teacher: teacher,
      tutor: tutor,
      location: location,
      help: help,
      comment: comment,
      fileList:  this.fileList
    }
    this.dialogRef.close({event: data});
  }
}