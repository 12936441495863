import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { of, EMPTY } from 'rxjs'
import { Router } from '@angular/router';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators'
import * as AuthActions from '../actions/auth.actions'
import {AuthService} from '../services/auth.service'
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class AuthEffects {
    //effect to handle login
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.login),
            exhaustMap(action =>
                this.authService.loginServer(action.email, action.password, action.remember).pipe(
                    map(output => {
                        let token = output['token'];
                        let userId = output['userId'];
                        let user_type = output['type'];
                        this.authService.storeToken(token);
                        localStorage.setItem('token', token);
                        localStorage.setItem('userId', userId);
                        localStorage.setItem('userType', user_type);
                        
                        let parsedToken = this.authService.parseToken(token);
                        
                        return AuthActions.loginSucceed({token,  parsedToken});
                    }),
                    catchError(error => of(AuthActions.loginFailure({ error })))
                )
            )
        )
    );
    //non dispatching effect to navigate after successful login
    loginSucceed$ = createEffect(()=>
      this.actions$.pipe(
          ofType(AuthActions.loginSucceed)          
      ),
      {dispatch: false}  
    );
    logout$ =  createEffect(()=>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            tap(output => console.log(output)),
            tap(()=> console.log("logout successfully"))
        ),
        {dispatch: false}
    )

    loginFailure$ = createEffect(()=>
      this.actions$.pipe(
          ofType(AuthActions.loginFailure),        
          tap((output)=> {              
            // this.notificationService.error('Error', output.error['error'], {
            //     timeOut: 3000,
            //     showProgressBar: true,
            //     pauseOnHover: true,
            //     clickToClose: true
            //   });
            localStorage.setItem('error', output.error['error'])

          })
      ),
      {dispatch: false}  
    );

    //effect to handle login
    join$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.join),
            exhaustMap(action =>
                this.authService.joinServer(action.email, action.password, action.schoolCode).pipe(
                    map(output => {
                        let token = output['token'];
                        let userId = output['userId'];
                        let user_type = output['type'];
                        this.authService.storeToken(token);
                        localStorage.setItem('token', token);
                        localStorage.setItem('userId', userId);
                        localStorage.setItem('userType', user_type);
                        
                        let parsedToken = this.authService.parseToken(token);
                        
                        return AuthActions.loginSucceed({token,  parsedToken});
                    }),
                    // catchError(() => EMPTY)                    
                    catchError(error => of(AuthActions.joinFailure({ error })))
                )
            )
        )
    );

    joinSucceed$ = createEffect(()=>
      this.actions$.pipe(
          ofType(AuthActions.joinSucceed)                    
      ),
      {dispatch: false}  
    );

    joinFailure$ = createEffect(()=>
      this.actions$.pipe(
          ofType(AuthActions.joinFailure),        
          tap((output)=> {
             this.notificationService.error('Error', output.error['error'], {
                 timeOut: 3000,
                 showProgressBar: true,
                 pauseOnHover: true,
                 clickToClose: true
               });

          })
      ),
      {dispatch: false}  
    );

    register$ = createEffect(()=>
        this.actions$.pipe(
            ofType(AuthActions.register),
            exhaustMap(action=>
                this.authService.registerServer(action.school, action.email, action.personalEmail, action.password).pipe(
                    map(output=>{
                        console.log(output);
                        let token = output['token'];
                        let parsedToken = this.authService.parseToken(token);
                        console.log(parsedToken);
                        return AuthActions.registerSucceed({token, parsedToken});
                    })
                )
            )
        )
    );
    registerSucceed$ = createEffect(()=>
      this.actions$.pipe(
          ofType(AuthActions.registerSucceed),
          tap(() => this.router.navigate(['/login']))
      ),
      {dispatch: false}  
    );
    profileCreate$ = createEffect(()=>
        this.actions$.pipe(
            ofType(AuthActions.profileCreation),
            exhaustMap(action=>
                this.authService.profileCreationServer(action.firstName, action.lastName, action.schoolName, action.birthday, action.gender, action.enableSMS, action.phoneNumber, action.gradeLevel, action.studentId, action.referrer,  action.personalEmail, action.parentEmail, action.enableParentEmail,action.token).pipe(
                    map(output=>{
                        console.log(output);
                        let token = output['token'];
                        localStorage.setItem('token', token)
                        let parsedToken = this.authService.parseToken(token);
                        return AuthActions.profileCreationSucceed({token, parsedToken});
                    })
                )
            )
        )
    )
    constructor(
        private actions$: Actions,
        private authService: AuthService, 
        private notificationService: NotificationsService,
        private router: Router
    ) {}
}

