import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NotificationsService } from 'angular2-notifications';



@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.css', '../onboarding.css']
})
export class AddPictureComponent implements OnInit {
  type:string;
  tuteeImg = "../../../../../assets/img/onboarding-progress-about-me-tutee.svg"
  tutorImg = "../../../../../assets/img/onboarding-progress-about-me-tutor.svg"
  zoom = 1
  fileControl=new FormControl()
  classes: any;
  constructor(
    private notificationService: NotificationsService,
    private router: Router,
    private authService: AuthService,
    private store: Store<any>,
    private compressor: NgxImageCompressService){}

  url: any;
  bytes = 20000;
    onSelectFile(event) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]); // read file as data url
          var comp = this
          reader.addEventListener("load", async function () {
            // convert image file to base64 string
            comp.url = reader.result
            comp.bytes = comp.compressor.byteCount(comp.url)

            while(comp.bytes > 100000){
              await comp.compressor.compressFile(comp.url, -1, 50, 50).then(result => {
                comp.url = result
                console.log("Bytes:", comp.compressor.byteCount(result))
                comp.bytes = comp.compressor.byteCount(result)
              })
            }
            
          }, false)
        }
    }
    
  onZoom(event)
  {
    var elem = document.getElementById("mydiv")
    elem.style.transform = "scale(" + event.value + ")"
    this.zoom = event.value
  }

  ngOnInit() {
    this.url = "../../../../../assets/img/profile-icon.png"
    this.dragElement(document.getElementById("mydiv"), document.getElementById("circle"));
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);   
      this.logout()     
      return
    }
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
      if (res['confirmed']){
        this.router.navigate([''])
      }
      this.classes = res['helpNeeded']
      this.type = res['type']})
      
  }
  
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout())          
  }

  onXClick(){
    this.url = "../../../../../assets/img/profile-icon.png"
    this.fileControl.setValue(null)
    document.getElementById("mydiv").style.top = "0px"
    document.getElementById("mydiv").style.left = "0px"

  }
  
  
  dragElement(elmnt, frame) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    elmnt.onpointerdown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onpointerup = closeDragElement;
      document.ontouchend = closeDragElement;
      document.onpointerout = closeDragElement;
      
      // call a function whenever the cursor moves:
      document.onpointermove = elementDrag;

    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.ontouchend = null;
      document.onpointermove = null;
      document.onpointerup = null;
      document.onpointerout = null;
    }
  }

  onSubmit(form: NgForm)
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()   
      return
    }
    var pic = document.getElementById("mydiv")
    console.log(pic.offsetTop)
    console.log(pic.offsetLeft)

    this.authService.update(localStorage.getItem('userId'), 
    {
      profilePicture: {
        img: this.url,
        xOffset: pic.offsetLeft,
        yOffset: pic.offsetTop,
        zoom: this.zoom
      },
      onboardingCompletion: {
        "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": true,
          "add-picture": true,
          "tutor-recommendation": false
      }

      }).subscribe(res =>
      {
        if(this.type == 'Tutor' || this.classes.length == 0)
        {
          this.router.navigate(['onboarding/signup-confirmation'])
          window.analytics.track("Profile Picture Submitted",{
            // img: this.url
          })
        }
        else {
          this.router.navigate(['onboarding/tutor-recommendation'])
          window.analytics.track("Profile Picture Submitted",{
            // img: this.url
          })
        }
      })
  }
  skip()
  {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.notificationService.error('Error', 'Your token has expired, please log in.', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          })
      this.router.navigate(['']);   
      this.logout()
      return
    }
    this.authService.update(localStorage.getItem('userId'), 
    {
      onboardingCompletion: {
        "type-select": true,
          "school-info": true,
          "personal-info": true,
          "classes": true,
          "about-me": true,
          "add-picture": true,
          "tutor-recommendation": false
      }

      }).subscribe(res =>
      {
        if(this.type == 'Tutor' || this.classes.length == 0)
        {
          this.router.navigate(['onboarding/signup-confirmation'])
        }
        else {
          this.router.navigate(['onboarding/tutor-recommendation'])
          window.analytics.track("Profile Picture Skipped",{
            // id: localStorage.getItem('userId')
          })
        }
      })
  }
  
}
