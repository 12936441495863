import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, CanDeactivate, Router } from '@angular/router';
import { AuthService } from 'src/app/store/services/auth.service';
import { SchoolService } from 'src/app/store/services/school.service';

import { logout } from 'src/app/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { AppointmentsService } from 'src/app/store/services/appointments.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SearchService } from 'src/app/store/services/search.service';
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate, WarningDialog } from '../warning/warning';
import { HelpDialog } from '../../dashboard/dashboard-page/dashboard-page.component';

@Component({
  selector: 'app-tutor-classes',
  templateUrl: './tutor-classes.component.html',
  styleUrls: ['../../onboarding/onboarding.css', './tutor-classes.component.css']
})
export class TutorClassesComponent implements OnInit, ComponentCanDeactivate {
  userData: any;
  username: string;
  schoolId: any;
  classes = [];
  classObjs= [];
  dialog: any;
  saved: boolean = false;

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private userService: AppointmentsService,
    private searchService: SearchService
  ) { }
  schoolName
  isMaintenance = false
  dashLogo
  route
  classCtrl = new FormControl()
  myClasses = []
  filteredClasses
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.saved
  }

  ngOnInit() {
    this.route = this.activatedRoute.snapshot;
    this.schoolName = this.route.params['schoolID'];   
    this.schoolService.getSchoolByName(this.schoolName).subscribe( data => {      
      this.isMaintenance = data.result['maintenance']
      this.dashLogo = data.result['dashLogo']
      this.schoolId = data.result['_id']


      let isAuthenticated = this.authService.isAuthenticated()    
      if (!isAuthenticated){
        
        this.goLoginPage();
        return
      }
      this.authService.getUserById(localStorage.getItem("userId")).subscribe(res => {
      
        this.searchService.getAllClasses(this.schoolId).subscribe(data => {
          (data.result as Array<Object>).map(entry => {
            if(res['courses'].includes(entry['_id']))
            {
              this.myClasses.push(entry['name'])
            }
            else
            {
              this.classes.push(entry['name'])
            }
            this.classObjs.push(entry)
          })
            
            
          console.log('class data', this.classes)
          //this.cloneClasses = Object.assign([], this.classes) 
          this.filteredClasses = this.classCtrl.valueChanges.pipe(
            startWith(null),
            map((aClass: string | null) => aClass ? this._filter(aClass) : this.classes.slice()));  
        })
      })
    })
    this.userService.getAppointments()
      .subscribe(data => {
        this.userData = data.result;
        // this.isMaintenance = this.userData.school.maintenance                        
        this.username = this.userData['firstname'] + ' ' + this.userData['lastname']
        // this.schoolID = this.userData['school']._id;
        // //This section is for account info div
        // this.type = this.userData['type']
        // this.grade = this.userData['grade']
        // this.enableSMS = this.userData['enableSMS']
        // this.email = this.userData['email']
        // this.dateCreated = this.userData['dateCreated'] // date object type may need some more looking into
        // this.birthday = this.userData['birthday']
        // this.firstName = this.userData['firstname']
        // this.gender = this.userData['gender']
        // this.lastName = this.userData['lastname']
        // this.parentEmail = this.userData['parentEmail']
        // this.personalEmail = this.userData['personalEmail']
        // this.phoneNumber = this.userData['phoneNumber']
        // this.studentID = this.userData['studentId']
        // this.referrer = this.userData['referrer']


        let short_schoolName = this.userData['school'].shortName;        
        let schoolNames = [];
        this.userData['schools'].forEach(school =>{
          schoolNames.push(school.shortName)
        })
      })
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.classes.filter(aClass => aClass.toLowerCase().includes(filterValue));
  }
  add(event: MatChipInputEvent)
  {
    const value = (event.value || '').trim();

    if (value) {
      this.myClasses.push(value);
    }

    // Clear the input value
    event.input.value = "";

    this.classCtrl.setValue(null);
  }
  remove(rmClass: string)
  {
    const index = this.myClasses.indexOf(rmClass);
    if (index >= 0) {
      this.classes.push(this.myClasses.splice(index, 1)[0]);
    }
    this.classCtrl.setValue("");
    console.log(this.myClasses)
    
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.classes.findIndex(item => item == event.option.viewValue)
    if(index >= 0)
    {
      this.classes.splice(index, 1)
    }
    this.myClasses.push(event.option.viewValue);
    this.classCtrl.setValue("");
  }
  goToHomePage(){
    this.router.navigate(['/school', this.schoolName, 'dashboard']);  
  }
  goToSearchPage(){
    this.router.navigate(['/school', this.schoolName, 'search']);  
  }
  goToProfilePage(){
    this.router.navigate(['/school', this.schoolName, 'profile']);
  }
  goToOnboarding(){
    this.router.navigate(['/school', this.schoolName, 'tutor-onboarding', 'classes'])
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    this.store.dispatch(logout())          
  }
  goLoginPage(){
    this.logout();
    this.router.navigate(['/school', this.schoolName, 'login']);  
    localStorage.setItem('destLink', 'search')
  }
  onLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');        
    localStorage.removeItem('destLink');        
    this.store.dispatch(logout());          
    this.router.navigate(['/school', this.schoolName, 'login']);  
  }
  onSubmit(){
    var courses = []
    console.log(this.classObjs)
    this.classObjs.forEach((value) => console.log(value.name))
    this.myClasses.forEach((value1) => {
      var course = this.classObjs.find((value2) => {console.log(value1, value2.name); return value1 == value2.name})
      courses.push(course)
      this.searchService.updateClass(course._id, this.schoolId).subscribe(res=>{})
    })
    setTimeout(() =>{
    this.authService.update(localStorage.getItem('userId'), {
        courses: courses 
      }).subscribe(res => { this.saved=true; this.router.navigate(['/school', this.schoolName, 'tutor-onboarding','availabilities'])})
    }, 100)
  }

  openWarningDialog(){
    const dialogRef = this.dialog.open(WarningDialog, {
      width: '70vw',
      data: {choice: ""}
    });

    dialogRef.afterClosed().subscribe(result => {})
  }

  openHelpDialog(){
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '500px',
      data: this.userData.school,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log("close help dialog", result)
    });
  }
}
