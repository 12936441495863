import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SchoolService } from 'src/app/store/services/school.service';

export interface DialogData {
  options:any
  choice:any
  code:any
}

@Component({
  selector: 'join-dialog',
  templateUrl: 'join-dialog.html',
  styleUrls: ['./join-dialog.css', '../onboarding/onboarding.css']
})

export class JoinDialog {

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<JoinDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
export function openJoinDialog(){
  let options = []
  this.authService.getUserById(localStorage.getItem('userId')).subscribe(user =>{
  this.schoolService.getSchools().subscribe(schools =>{
    console.log(schools)
    schools.forEach(school => {
      if(school.id != user.school && user.schools.findIndex(item => item == school.id) == -1)
      {
        console.log(school.studentDomains)
        if(school.studentDomains == undefined || school.studentDomains.length == 0)
        {
          options.push(school)
        }
        else if(school.studentDomains.includes(user.email.substring(user.email.lastIndexOf('@')+1)))
        {
          options.push(school)
        }
      }
    })
  const dialogRef = this.dialog.open(JoinDialog, {
    width: '70vw',
    data: {options: options, choice: "", code:""}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if(result != undefined)
    {
      this.authService.joinServer(user.email, result.choice, result.code).subscribe(res =>
        {this.router.navigate(['school', schools.find(item => item.id == result.choice).short, 'dashboard']).then(()=>window.location.reload())
      }, err=>{this.notificationService.error('Error', err.error, {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        })
      })
    }
  })
})})
}
