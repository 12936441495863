import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/store/services/auth.service';
import { logout } from 'src/app/store/actions/auth.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SchoolService } from 'src/app/store/services/school.service';
import { ScheduleService } from 'src/app/store/services/schedule.service';

export interface DialogData {
  email: string;
  newEmail: string;
  studentDomains: any;
  badDomain: Boolean;
  checkDomain: boolean;
  school_name: string;
  duplicate: any;
}

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.css', '../onboarding.css']
})
export class SignupConfirmationComponent implements OnInit {
  tuteeImg = "../../../../../assets/img/onboarding-progress-complete-tutee.svg"
  tutorImg = "../../../../../assets/img/onboarding-progress-complete-tutor.svg"
  email: any;
  studentDomains: any;
  school_name: any;
  loginEmail: any;
  personalEmail: any;
  parentEmail: any;
  unapproved: boolean = false;
  appointments = [];
  classes = [];
  bookButtonOn
  type: any;

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private authService:AuthService,
    private router: Router,
    private schoolService: SchoolService,
    private scheduleService: ScheduleService) {}

  ngOnInit() {
    let isAuthenticated = this.authService.isAuthenticated()    
    if (!isAuthenticated){
      this.router.navigate(['']);   
      this.logout()     
      return
    }
    
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(user =>{
      if (user['confirmed']){
        this.router.navigate([''])
      }
      this.type = user['type']
      this.classes = user['helpNeeded']
      this.bookButtonOn = parseInt(localStorage.getItem('savedClassIndex')) < this.classes.length
      user['appointments'].forEach(appt => 
        this.scheduleService.getAppointmentById(appt.appt).subscribe(appointment => {
          this.appointments.push(appointment.result['content'])
          console.log(appointment.result['content'])
        }))
      this.loginEmail = user['email']
      this.personalEmail = user['personalEmail']
      this.parentEmail = user['parentEmail']
      if (!user["approved"])
      {
        this.unapproved = true
      }
      this.schoolService.getSchoolbyID(user['school']).subscribe(school =>{
        this.studentDomains = school['studentDomains']
        this.school_name = school['name']
      })
    })
    if (localStorage.getItem("emailSent") == null)
    {

      this.authService.sendEmail(localStorage.getItem("userId")).subscribe( res=>{
        this.email = res
        localStorage.setItem("emailSent", this.email)
        if (this.type == 'Parent')
        {
          this.authService.sendParentInformation(localStorage.getItem("userId")).subscribe( res=>{})
        }
        else if (this.unapproved)
        {
          this.authService.sendParentEmail(localStorage.getItem("userId")).subscribe( res=>{})
        }
        else{
          this.authService.sendParentConsent(localStorage.getItem("userId")).subscribe( res=>{})
        }
        
      })
      
    }
    else{
      this.email = localStorage.getItem("emailSent")
    }
    
    // this.tokenRefresh()
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');    
    localStorage.removeItem('deskLink');
    this.store.dispatch(logout)
  }
  resendEmail()
  {
    this.authService.sendEmail(localStorage.getItem("userId")).subscribe( res=>{
      this.email = res
    })
  }

  updateEmail()
  {
    const dialogRef = this.dialog.open(UpdateEmailDialog, {
      width: '70vw',
      data: {email: this.email, newEmail: "", studentDomains: this.studentDomains, badDomain: false, checkDomain: this.email == this.loginEmail , school_name: this.school_name, duplicate: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && !result.badDomain && !result.duplicate)
      {
        if (this.email == this.loginEmail)
        {
        this.authService.update(localStorage['userId'], {email: result.newEmail}).subscribe(res =>{
          this.resendEmail()
          this.email = result.newEmail;
        })
        }
        else if (this.email == this.personalEmail)
        {
          this.authService.update(localStorage['userId'], {personalEmail: result.newEmail}).subscribe(res =>{
            this.resendEmail()
            this.email = result.newEmail;
          })
        }
        else if (this.email == this.parentEmail)
        {
          this.authService.update(localStorage['userId'], {parentEmail: result.newEmail}).subscribe(res =>{
            this.resendEmail()
            this.email = result.newEmail;
          })
        }
        
        
        
      }
    });
  }
  bookAnother(){
    this.router.navigate(['onboarding', 'tutor-recommendation'])
  }
  

}

@Component({
  selector: 'update-email-dialog',
  templateUrl: 'update-email-dialog.html',
  styleUrls: ['./signup-confirmation.component.css', '../onboarding.css']
})
export class UpdateEmailDialog {

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<UpdateEmailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  checkEmail()
  {
    if(this.data.checkDomain){
    this.authService.emailExists(this.data.newEmail).subscribe(res =>{
      this.data.duplicate = res}
    )}
    if(this.data.studentDomains == undefined || this.data.studentDomains.length == 0 || this.data.checkDomain == false)
    {
      this.data.badDomain = false
    }
    else if(this.data.studentDomains.findIndex(domain => domain.domain == this.data.newEmail.substring(this.data.newEmail.lastIndexOf('@')+1) 
        && domain.acceptEmail) == -1)
    {
      this.data.badDomain = true
    }
    else
    {
      this.data.badDomain = false
    }

  }

}
