import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/store/services/auth.service';

@Component({
  selector: 'app-verification-confirmation',
  templateUrl: './verification-confirmation.component.html',
  styleUrls: ['./verification-confirmation.component.css', '../onboarding.css']
})
export class VerificationConfirmationComponent implements OnInit {

  route: ActivatedRouteSnapshot
  userId: string
  verificationCode: string
  type: any
  appointments = []
  constructor(private activatedRoute: ActivatedRoute, 
    private authService: AuthService,
    private router: Router) {
      this.activatedRoute.queryParams.subscribe(params => {             
      this.userId = params['userId'];            
      this.verificationCode = params['verificationCode']
    })
  }
  

  ngOnInit() {
    this.authService.confirmUser(this.userId, this.verificationCode).subscribe( res =>
      {})
    this.authService.getUserById(localStorage.getItem("userId")).subscribe(user =>{
      this.type = user['type']
      this.appointments = user['appointments']
    })
    

  }

  toDashboard() {
    this.router.navigate(['school/' + localStorage.getItem('schoolShortName') + '/dashboard'])
  }

}
