import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { map, take} from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class SchoolService {
    httpConfig = {}
    private jwtHelper: JwtHelperService;
    constructor(private http: HttpClient) {
        this.jwtHelper = new JwtHelperService();
    }
    setupHttpConfig(){
        let token = localStorage.getItem('token')
        this.httpConfig = {
            headers: new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            })
        };              
    }
    getSchools() {        
        // return this.http.get('http://localhost:5000/api/schools');
        return this.http.get(`${environment.backend}/api/schools`);
    }
    getSchoolbyID(id){
        return this.http.get(`${environment.backend}/api/schools/${id}`);
    }
    getDurationbyID(id){
        return this.http.get(`${environment.backend}/api/schools/duration/${id}`);
    }
    getSchoolByName(shortName: string) {         
        return this.http.post(`${environment.backend}/api/schools`, {shortName}).pipe(
            map(res=>({                       
                result: res
            })),
            take(1)
        );
    }
    addUser(userId, type, schoolId)
    {
        return this.http.post(`${environment.backend}/api/schools/add`, {userId:userId, type:type, schoolId:schoolId})
    }
}
